import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import GroupContract from "../GroupContract";
import { isUndefined } from "lodash";

const CreateEditGroupContract = ({
  onDelete,
  contractID,
  facilityID,
  onChange,
  onAccessHolderSelected,
}) => {
  const { contractID: routeID } = useParams();
  contractID = !isUndefined(contractID)
    ? contractID
    : routeID !== "add"
    ? routeID
    : undefined;

  return (
    <div>
      <GroupContract
        contractID={contractID}
        facilityID={facilityID}
        onChange={onChange}
        onDelete={onDelete}
        onAccessHolderSelected={onAccessHolderSelected}
      />
    </div>
  );
};

CreateEditGroupContract.defaultProps = {
  onDelete: () => {},
  unMount: () => {},
};

CreateEditGroupContract.propTypes = {
  onDelete: PropTypes.func,
  unMount: PropTypes.func,
};

export default CreateEditGroupContract;
