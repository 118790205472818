import React, { useState, useEffect } from "react";
import { Grid, Typography, Divider, Drawer, Button } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import GPIOMappingTable from "./GPIOMappingTable";
import GPIOSideDrawer from "./GPIOSideDrawer";
import GPIOService from "../../services/GPIOService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import RateService from "../../services/RateService";

const gpioService = new GPIOService(apiClient);
const rateService = new RateService(apiClient);

const GPIOConfiguration = ({
  deviceID
}) => {
  const classes = useStyles();
  const { textConfirmation } = useConfirmationDialog();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [GPIOMappings, setGPIOMappings] = useState([]);
  const [ioMappingIDUsedForRateTrigger, setIOMappingIDUsedForRateTrigger] = useState({});
  const [actionableEvents, setActionableEvents] = useState([]);
  const [ioMap, setIoMap] = useState({});

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const onEditClick = (ioMappingID) => {
    var recordToEdit = GPIOMappings.find(x => x.ioMappingID == ioMappingID);

    setIoMap({ ...recordToEdit })
    setDrawerOpen(true);
  };

  const onAddClick = () => {
    setIoMap({});
    setDrawerOpen(true);
  }

  const handleSave = async (ioMapping) => {
    var errorMessage;
    try {
      if (ioMapping.ioMappingID != undefined) {
        errorMessage = "Failed to update GPIOMapping"
        var result = await gpioService.UpdateIOMapping(ioMapping);

        let list = [...GPIOMappings]
        list[list.findIndex(x => x.ioMappingID == ioMapping.ioMappingID)] = result.data;

        setGPIOMappings([...list])

        handleDrawerClose();
      } else {
        errorMessage = "Failed to create GPIOMapping"
        var result = await gpioService.createIOMapping(ioMapping);

        let list = [...GPIOMappings]
        list.push(result.data);
        setGPIOMappings([...list])

        handleDrawerClose();
      }
    } catch {
      enqueueSnackbar(errorMessage, {
        variant: "error",
        tag: "createUpdateGPIOError"
      });
    }
  };

  const GetGPIOMappingInfo = async () => {
    try {
      var result = await gpioService.getIOMappings(deviceID);
      setGPIOMappings(result.data);
    } catch (error) {
      enqueueSnackbar("Failed to get GPIOMappings", {
        variant: "error",
        tag: "getGPIOError"
      });
    }
  }

  const GetActionableEvents = async () => {
    try {
      var result = await gpioService.GetActionableEvents();
      result.data.unshift({
        systemEventID: 0,
        eventLocalTextID: 0,
        eventName: "None",
        name: "None",
        description: "None"
      })
      setActionableEvents([...result.data])
    } catch (error) {
      enqueueSnackbar("Failed to get system events", {
        variant: "error",
        tag: "getSystemEventsError"
      });
    }
  }

  const GetRateAssignedToEntity = async () => {
    try {
      var result = await rateService.getRateAssignedToEntity(deviceID);
      setIOMappingIDUsedForRateTrigger(result.data.entityRateDetails?.secondaryRateTriggerID);
    } catch (error) {
      enqueueSnackbar("Unable to retrieve rates for this entity", {
        variant: "error",
        tag: "FailedToFindRatesForEntity"
      });
    }
  }

  useEffect(() => {
    GetGPIOMappingInfo();
    GetActionableEvents();
    GetRateAssignedToEntity();
  }, [deviceID])

  const handleDeletion = async (ioMappingID) => {
    let continueDelete = await textConfirmation({
      title: `Are you sure you want to delete this GPIO mapping?`,
      message: "Please enter the GPIO mapping name EXACTLY as shown in the input box below to delete.",
      textConfirmation: GPIOMappings.find(x => x.ioMappingID == ioMappingID).friendlyName,
    });

    if (!continueDelete) return;

    onDeleteClick(ioMappingID)
  }

  const onDeleteClick = async (ioMappingID) => {
    try {
      await gpioService.DeleteIOMapping(deviceID, ioMappingID);

      var list = [...GPIOMappings]
      list = list.filter(x => x.ioMappingID != ioMappingID);

      setGPIOMappings([...list]);
    } catch (error) {
      if (error.response?.status === 409) {
        enqueueSnackbar("This IO mapping is currently being used", {
          variant: "error",
          tag: "ioMappingExistsError",
        });
      } else {
        enqueueSnackbar("Failed to delete GPIOMappings", {
          variant: "error",
          tag: "failedToDeleteGPIOMappingError",
        });
      }
    }
  };

  return (
    <>
      <Grid container className={clsx(`title-container`, classes.container)}>
        <Grid item xs={12} sm={12} md={11} lg={11} xl={11} style={{ height: "50px" }}>
          <Typography variant="h5" color="primary" component="h2" name="title" className={clsx("title", classes.title)}>
            GPIO Mappings
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1} className={clsx("add-button-grid", classes.addButtonGrid)}>
          <Button
            color="primary"
            variant="outlined"
            onClick={onAddClick}
            className={clsx("add-button")}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12} >
          <Divider style={{ marginBottom: 15 }}></Divider>
        </Grid>

        <GPIOMappingTable IOMappingList={GPIOMappings} onEditClick={onEditClick} onDeleteClick={handleDeletion} ioMappingIDUsedForRateTrigger={ioMappingIDUsedForRateTrigger} />
      </Grid>
      <Drawer
        className={clsx(classes.drawer, 'add-edit-gpio-drawer')}
        anchor="right"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
          root: classes.drawer,
        }}
      >
        <GPIOSideDrawer ioMap={ioMap} handleSave={handleSave} handleCancel={handleDrawerClose} deviceID={deviceID} systemEvents={actionableEvents} isUsedForRateTrigger={ioMappingIDUsedForRateTrigger && ioMappingIDUsedForRateTrigger == ioMap.ioMappingID} />
      </Drawer>
    </>
  );
};

export default GPIOConfiguration;
