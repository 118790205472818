import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import GatewaySettings from "./GatewaySettings";
import { useGatewayContext } from "../../providers/CreditCardGatewayProvider";
import GatewayDropDown from "./GatewayDropDown";
import Grid from "@material-ui/core/Grid";
import { Divider, Typography } from "@material-ui/core";
import useHasPermissions from "../../hooks/useHasPermissions";
import { useFlags } from "launchdarkly-react-client-sdk";

function GatewayConfiguration({
  entityType,
  onClose,
  facilityID
}) {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { planetCcGateway } = useFlags();
  const { functions: {
    getCreditCardGateways,
    getCreditCardGatewaySettingsByEntityID
  } } = useGatewayContext();

  // Array of gateways from the creditcardgateways table
  const [gateways, setGateways] = useState([]);
  const [gatewaySettings, setGatewaySettings] = useState();
  const [gatewaySettingsParent, setGatewaySettingsParent] = useState();

  // Selected gateway settings passed to components so they can create the settings.
  const [gatewayDropdownValue, setgatewayDropdownValue] = useState({
    creditCardGatewayID: "",
    gatewayName: ""
  });

  const { hasPermissions } = useHasPermissions();
  const EditCCGatewayPermission = hasPermissions(["ccgateway.edit"]);
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);

  // Populate gateway drop down list with gateways
  useEffect(() => {
    getCreditCardGateways()
      .then(result => {
        if (result.data.length > 0) {
          setCreditCardGateways(result.data);
        } else {
          enqueueSnackbar("Could not find any gateways to display!", {
            variant: "error",
            tag: "FailedToFindGatewaysToDisplay"
          });
        }
      })
      .catch(err => {
        enqueueSnackbar("Could not find any gateways to display!", {
          variant: "error",
          tag: "FailedToFindGatewaysToDisplay"
        });
      });
  }, []);

  const setCreditCardGateways = gateways => {
    let filtered = gateways.filter(
      (row) => !planetCcGateway ? row.gatewayName.toLowerCase() === "windcave" : true
    );
    setGateways(filtered);
  }

  // Get all existing cc gateway settings for facility / facility group
  useEffect(() => {
    loadCreditCardGatewaySettings();
  }, []);

  const loadCreditCardGatewaySettings = () => {
    if (facilityGroupId) {
      Promise.all([ 
        getCreditCardGatewaySettingsByEntityID(facilityGroupId), 
        getCreditCardGatewaySettingsByEntityID(facilityID) 
      ]).then((responses) => {
        setGatewaySettingsParent(responses[0].data || []);
        setGatewaySettings(responses[1].data || []);
      }).catch(err => {
        setGatewaySettingsParent([]);
        setGatewaySettings([]);
        console.error(err);
      });
    } else {
      getCreditCardGatewaySettingsByEntityID(facilityID).then(result => {
        setGatewaySettings(result.data || []);
      }).catch(err => {
        setGatewaySettings([]);
        console.error(err);
      });
    }
  };

  const handleSelectChange = item => {
    setgatewayDropdownValue({
      ...gatewayDropdownValue,
      creditCardGatewayID: item.id,
      gatewayName: item.name
    });
  };

  return (
    <div style={{ minWidth: 450, paddingLeft: 20, paddingRight: 20 }}>
      <Typography variant="h5" color="primary" component="h2">
        Credit Card Gateway
      </Typography>
      <Divider style={{ marginBottom: 15 }}></Divider>
      <Grid container>
        <Grid item>
          <GatewayDropDown
            onChange={handleSelectChange}
            gateways={gateways}
            gatewaySettings={gatewaySettings}
          />
          { gatewaySettings &&
            <GatewaySettings
              facilityID={facilityID}
              disabled={!EditCCGatewayPermission}
              onClose={onClose}
              entityType={entityType}
              gatewaySettings={gatewaySettings}
              gatewaySettingsParent={gatewaySettingsParent}
              gatewayDropdownValue={gatewayDropdownValue}
              onSave={loadCreditCardGatewaySettings}
            />
          }
        </Grid>
      </Grid>
    </div>
  );
}

GatewayConfiguration.defaultProps = {
  onClose: () => {}
};

GatewayConfiguration.propTypes = {
  onClose: PropTypes.func,
  entityType: PropTypes.oneOf(["facility", "device"]).isRequired
};

export default React.memo(GatewayConfiguration);
