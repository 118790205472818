import React from "react";
import { useStyles } from "./style";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";
import { isUndefined, isNull } from "lodash";
import useHasPermissions from "../../../hooks/useHasPermissions";

export const ContractAccessHoldersTable = ({
  accessHolders,
  onSelect,
  onDelete,
}) => {
  const classes = useStyles();

  const { convertUtcDateToFormat } = useCurrentFacilityTimezone();
  const formatDate = (dateStr) => {
    if (isUndefined(dateStr) || isNull(dateStr)) {
      return "";
    }
    return convertUtcDateToFormat(dateStr);
  };
  const { hasPermissions } = useHasPermissions();
  const editAccessHoldersAndGroupContract = hasPermissions([
    "groupcontracts.edit",
    "accessholders.edit",
  ]);
  const accessHoldersView = hasPermissions(["accessholders.view"]);

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const { textConfirmation } = useConfirmationDialog();

  const handleRemoveAccessHolder = async (e, id) => {
    e.stopPropagation();
    const confirmed = await textConfirmation({
      title: "Remove Access Holder",
      message:
        "To Confirm removing this access holder from the group contract, please type 'Remove' below",
      textConfirmation: "Remove",
    });
    if (confirmed) {
      onDelete(id);
    }
  };

  const handleAccessHolderSelected = (id) => {
    onSelect(id);
  };

  return (
    <Grid container>
      <Table size="small" className={clsx("contract-ah-table", classes.root)}>
        {!small && (
          <TableHead className={clsx("table-head")}>
            <TableRow className={clsx("contract-ah-row", classes.headerRow)}>
              <Grid container direction="row">
                <Grid item xs={4} md={3}>
                  <TableCell
                    data-id="contract-ah-title-name"
                    data-testid="contract-ah-title-name"
                    className={clsx("name", classes.tableCell)}
                  >
                    Name
                  </TableCell>
                </Grid>
                <Grid item xs={4} md={3}>
                  <TableCell
                    data-id="contract-ah-title-start-date"
                    data-testid="contract-ah-title-start-date"
                    className={clsx("start-date", classes.tableCell)}
                  >
                    Start Date
                  </TableCell>
                </Grid>
                <Grid item xs={4} md={3}>
                  <TableCell
                    data-id="contract-ah-title-end-date"
                    data-testid="contract-ah-title-end-date"
                    className={clsx("end-date", classes.tableCell)}
                  >
                    End Date
                  </TableCell>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TableCell
                    className={clsx("delete-col", classes.tableCell)}
                  ></TableCell>
                </Grid>
              </Grid>
            </TableRow>
            <Divider />
          </TableHead>
        )}
        <TableBody className={clsx("table-body")}>
          {accessHoldersView &&
            accessHolders?.map((row, index) => (
              <>
                <TableRow
                  key={index}
                  data-id={`contract-ah-row-${index}`}
                  data-testid={`contract-ah-row-${index}`}
                  className={clsx("contract-ah-row", classes.clickableRow)}
                  onClick={() => handleAccessHolderSelected(row.accessHolderID)}
                  data-access-holder-name={row.firstName}
                >
                  <Grid container direction="row" key={index}>
                    <Grid item xs={12} md={3}>
                      <TableCell
                        className={clsx("ah-name", classes.tableCell)}
                        data-id={`ah-name-${index}`}
                        data-testid={`ah-name-${index}`}
                        data-value={`${row.firstName} ${row.lastName}`}
                      >
                        {(small ? "Name: " : "") +
                          `${row.firstName} ${row.lastName}`}
                      </TableCell>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TableCell
                        className={clsx("ah-start", classes.tableCell)}
                        data-id={`ah-start-${index}`}
                        data-value={row.startDate}
                      >
                        {(small ? "Start Date: " : "") +
                          formatDate(row.startDate)}
                      </TableCell>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TableCell
                        className={clsx("ah-end", classes.tableCell)}
                        data-id={`ah-end-${index}`}
                        data-value={row.endDate}
                      >
                        {(small ? "End Date: " : "") + formatDate(row.endDate)}
                      </TableCell>
                    </Grid>
                    <Grid item xs={12} md={1}>
                      {editAccessHoldersAndGroupContract && (
                        <Button
                          className={clsx(
                            "btn-acc-remove",
                            classes.removeButton
                          )}
                          data-id={`remove-ah-btn-${index}`}
                          data-testid={`remove-ah-btn-${index}`}
                          variant="contained"
                          color="secondary"
                          onClick={(e) =>
                            handleRemoveAccessHolder(e, row.accessHolderID)
                          }
                          startIcon={
                            small && (
                              <DeleteIcon
                                fontSize="default"
                                className={clsx(classes.icon)}
                              />
                            )
                          }
                        >
                          {!small && "Remove"}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </TableRow>
                <Divider />
              </>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

ContractAccessHoldersTable.defaultProps = {
  accessHolders: [],
  onSelect: () => {},
  onDelete: () => {},
  facilityID: undefined,
};
ContractAccessHoldersTable.propTypes = {
  accessHolders: PropTypes.array,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ContractAccessHoldersTable;
