import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import {
	Box,
	Grid,
	Button,
	Divider,
	FormControl,
	MenuItem,
	TextField,
} from "@material-ui/core";
import Title from "../../Title";

/* Form */
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* Services */
import settingsService from "../../../services/SettingsService";
import apiClient from "../../../auth/apiClient";

import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";

/* Utilities */
import clsx from "clsx";
import * as Yup from "yup";

/* Style */
import { useStyles } from "./styles";

export const guesttypeSchema = Yup.object().shape({
	entityID: Yup.string(),
	name: Yup.string().trim("No leading or trailing whitespaces...")
		.required("Required")
		.min(3, "Cannot be less than 3 characters")
		.max(50, "Name must 50 characters or less"),
	ticketStartRange: Yup.string().required("Required").matches(/^\d+$/, "Required and Must be a Number"),
	ticketEndRange: Yup.string().required("Required").matches(/^\d+$/, "Required and Must be a Number"),
	prefix: Yup.string()
		.max(3, 'Prefix must be Exactly three characters')
		.nullable() // Allow null values
		.matches(/^[a-zA-Z]*$/, 'Prefix must only include letters') // Only letters allowed, including empty string
		.transform(value => value ? value.toUpperCase() : value), // Transform input to uppercase
	systemGuestTypeID: Yup.number().required("Required").typeError("Required"),
	rateID: Yup.string().required("Required")
});

  //"isEditable" variable is used to check whether guest types can be editable or not.
  //(Valet Area, Facility Group Under Tenant -> Editable)
  //(Valet Area under Facilty Group -> View Only).
const GuestTypeConfigurationForm = ({
	handleSave,
	handleEdit,
	handleCancel,
	guesttype,
	systemguesttypes,
	rates,
	isEditable,
}) => {

	const settingService = new settingsService(apiClient);
	
	const enqueueSnackbar = useEnqueueSnackbar();
	const [ticketLength, setTicketLength] = useState([]);

	const classes = useStyles();
	const {
		handleSubmit,
		control,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: { ...guesttype },
		resolver: yupResolver(guesttypeSchema),
		shouldUnregister: false,
	});

	const onSubmit = async (data) => {
		data.ticketLength = ticketLength;
		if (guesttype.guestTypeID === "") {
			await handleSave(data);
		} else {
			await handleEdit(data);
		}
	};

	const GetTicketLengthSettingValue = async () => {
		try {
			let ticketLengthSettingName = "setting.valetareaticketlength";
			let result = await settingService.getSettingByName(guesttype.entityID, ticketLengthSettingName);
			setTicketLength(JSON.parse(result.data["setting.valetareaticketlength"]));
		} catch (error) {
			enqueueSnackbar("Failed to get Ticket Length Setting Value", {
				variant: "error",
				tag: "getTicketLength"
			});
		}
	}

	useEffect(() => {
		GetTicketLengthSettingValue();
	}, [])

	return (
		<div className={classes.guesttypesDrawer} data-guesttype-id={guesttype.guestTypeID === "" ? "new" : guesttype.guestTypeID}>
			<Box className={clsx([classes.drawerDetailConfiguration])}>
				<Box className={classes.guesttypeBox}>
					<Grid>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Title>{(guesttype.guestTypeID == "") ? "Add a Guest Type" : (isEditable) ? "Edit Guest Type" : "Guest Type Details"}</Title>
						</Grid>
						<div>
							<Divider className={classes.guesttypeDividerFixed} />
						</div>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="name"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="Name *" role="guest-type-name">
										<TextField
											{...field}
											id="name"
											className={clsx("name")}
											label="Name *"
											variant="outlined"
											error={!!errors.name}
											helperText={errors.name && errors.name.message}
											InputProps={{
												disabled: !isEditable
											}}
										/>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="ticketStartRange"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="Ticket Range Start *" role="ticket-start-range">
										<TextField
											{...field}
											id="ticketStartRange"
											className={clsx("ticketStartRange")}
											label="Ticket Range Start *"
											variant="outlined"
											error={!!errors.ticketStartRange}
											helperText={errors.ticketStartRange && errors.ticketStartRange.message}
											InputProps={{
												disabled: !isEditable
											}}
										/>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="ticketEndRange"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="Ticket Range End *" role="ticket-end-range">
										<TextField
											{...field}
											id="ticketEndRange"
											className={clsx("ticketEndRange")}
											label="Ticket Range End *"
											variant="outlined"
											error={!!errors.ticketEndRange}
											helperText={errors.ticketEndRange && errors.ticketEndRange.message}
											InputProps={{
												disabled: !isEditable
											}}
										/>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="prefix"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="prefix" role="prefix">
										<TextField
											{...field}
											id="prefix"
											className={clsx("prefix")}
											label="Prefix"
											variant="outlined"
											error={!!errors.prefix}
											helperText={errors.prefix && errors.prefix.message}
											InputProps={{
												disabled: !isEditable
											}}
										/>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="systemGuestTypeID"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="systemGuestTypeID" margin="1">
										<TextField
											{...field}
											id="systemGuestTypeID"
											className={clsx("systemGuestTypeID", classes.flexInput)}
											label="System Guest Type *"
											data-testid="systemGuestTypeid-select"
											select
											variant="outlined"
											error={!!errors.systemGuestTypeID}
											helperText={errors.systemGuestTypeID && errors.systemGuestTypeID.message}
											InputProps={{
												disabled: !isEditable
											}}
										>
											{systemguesttypes && systemguesttypes.length > 0 && systemguesttypes.map((systemguesttype) => {
												return (
													<MenuItem
														className={clsx(`${systemguesttype.name}`)}
														key={systemguesttype.systemGuestTypeID}
														value={systemguesttype.systemGuestTypeID}
														fieldName="systemGuestTypeID"
													>
														{systemguesttype.name}
													</MenuItem>
												);
											})}
										</TextField>
									</FormControl>
								)}
							/>
						</Grid>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="rateID"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth FormLabel="rateID" margin="1">
										<TextField
											{...field}
											id="rateID"
											className={clsx("rateID", classes.flexInput)}
											label="Rate *"
											data-testid="rate-select"
											select
											variant="outlined"
											error={!!errors.rateID}
											helperText={errors.rateID && errors.rateID.message}
											InputProps={{
												disabled: !isEditable
											}}
										>
											{rates && rates.length > 0 && rates.map((rate) => {
												return (
													<MenuItem
														data-name={`${rate.name}`}
														key={rate.rateID}
														value={rate.rateID}
														fieldName="rateID"
													>
														{rate.name}
													</MenuItem>
												);
											})}
										</TextField>
									</FormControl>
								)}
							/>
						</Grid>
					</Grid>
				</Box>
				<Box className={clsx([classes.drawerDetailConfiguration])}>
					<div>
						<Divider className={classes.guesttypeDividerFixed} />
					</div>
					<Button
						className={clsx("cancelBtn", classes.btnControl)}
						name="cancel"
						variant="contained"
						onClick={handleCancel}
						disabled={isSubmitting}
						data-testid="cancelBtn"
					>
						{isEditable ? "Cancel" : "Close"}
					</Button>
					{isEditable && (
						<Button
							color="primary"
							name="submit"
							type="submit"
							variant="contained"
							className={clsx("saveBtn", classes.btnControl)}
							onClick={handleSubmit(onSubmit)}
							disabled={isSubmitting}
							data-testid="saveBtn"
						>
							Save
						</Button>
					)}
				</Box>
			</Box>
		</div>
	);
};

GuestTypeConfigurationForm.defaultProps = {
	handleSave: () => { },
	handleEdit: () => { },
	handleCancel: () => { },
	guesttype: {},
	systemguesttypes: [],
	rates: [],
	isEditable: true
};

GuestTypeConfigurationForm.propTypes = {
	handleSave: PropTypes.func,
	handleEdit: PropTypes.func,
	handleCancel: PropTypes.func,
	guesttype: PropTypes.shape({
		entityID: PropTypes.string,
		name: PropTypes.string,
		ticketStartRange: PropTypes.number,
		ticketEndRange: PropTypes.number,
		prefix: PropTypes.string,
		systemGuestTypeID: PropTypes.number,
		rateID: PropTypes.string,
		ticketLength: PropTypes.number
	}),
	systemguesttypes: PropTypes.array,
	rates: PropTypes.array,
	isEditable: PropTypes.bool
};

export default GuestTypeConfigurationForm;