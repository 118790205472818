import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cardContent: {
    width: "100%",
    paddingLeft: theme.spacing(3),
  },
  ThirdPartyVendorTitle: {
    color: theme.palette.white?.main,
    fontWeight: "bold",
    paddingTop: theme.spacing(1),
    width: "fit-content",
  },
  header: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
    [theme.breakpoints.up("lg")]: {
      maxHeight: "20px",
    },
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
      height: "230px", 
    },
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
      height: "230px",
    },
  },
  headerLeft: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  mobileBtn: {
    marginRight: theme.spacing(1)
  },
  vendorNameInput: {
    minWidth: "46%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  contactLabels: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        minWidth: "95%",
      },
      [theme.breakpoints.down("md")]: {
        minWidth: "95%",
      },
  },
  nameInputs: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "46.5%",
    [theme.breakpoints.up("lg")]: {
      minwidth: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  addressInputs: {
      marginTop: theme.spacing(1),
      // width: "435px",
      width: "95%",
      [theme.breakpoints.down("sm")]: {
        minWidth: "95%",
      },
      [theme.breakpoints.down("md")]: {
        minWidth: "95%",
      },
  },
  cityInput: {
    marginTop: theme.spacing(1),
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      Width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      Width: "95%",
    },
  },
  stateInput: {
    marginTop: theme.spacing(1),
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  zipInput: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: "68%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(0),
      minWidth: "95%",
    },
  },
  phoneInputs: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  emailInput: {
    marginTop: theme.spacing(1),
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "95%",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "95%",
    },
  },
  credentialButtons: {
    marginTop:theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  btnControl: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  emailDrawerButton: {
    marginTop: theme.spacing(1),
    marginRight: "5%"
  }
}));
