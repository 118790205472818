import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {useLocation} from "react-router-dom";

function ErrorMessage({error}) {
  return (
    <Container style={{marginTop: "1rem", textAlign: "center"}}>
      <Typography variant="h4" color="error">
        Oops! Something went wrong!
      </Typography>
      <Typography variant="h6" style={{marginTop: "2rem"}}>
        Navigate to another page or refresh to try again.
      </Typography>
    </Container>
  )
}

function RouteErrorBoundary(props) {
  const location = useLocation();
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage} resetKeys={[location]}>
      {props.children}
    </ErrorBoundary>
  )
}

export default RouteErrorBoundary;