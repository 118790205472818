import { makeStyles, lighten } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "medium"
  },
  filterIcon: {
    padding: "5px",
    float: "right",
  },
  tableCell: {
    fontSize: "1.2em",
    borderBottom: "none",
    padding: theme.spacing(1),
    justifyContent: "left"
  },
  drawerPaper: {
    width: "50%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  pointer: {
    cursor: "pointer",
  },
  resultText: {
    background: theme.palette.secondary.main,
    color: "#fff"
  },
  activityAccordion: {
    overflowY: "auto",
    overflowX: "none"
  },
  deleteButton: {
    alignItems: "center",
    alignSelf: "center",
  },
  editButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      marginLeft: theme.spacing(1)
    }
  },
  removeButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      marginLeft: theme.spacing(1)
    }
  },
  clickableRow: {
    cursor: "pointer",
    margin: "5px",
    "&:hover": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor:lighten(theme.palette.primary.light,.8),
    },
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },
}));
