import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/pro-regular-svg-icons";

const HotelRatesMappingTable = ({ hotelRatesList, accessGroups, onEditClick, onDeleteClick }) => {
  const classes = useStyles();
  const { height } = useWindowDimensions();
  const getAccessGroupName = (accessGroupID) => {
    let ag =  accessGroups.find(x=>x.accessGroupID === accessGroupID);
    return ag?.name;
  };
 
  return (
    <>
      <TableContainer
        className={clsx("hotel-mapping", classes.tableContainer)}
        style={{ maxHeight: height * 0.8 }}
      >
        <Table className={clsx("table", classes.table)} stickyHeader>
          <TableHead>
            <TableRow className={clsx("mapping-labels")}>
              <TableCell className={clsx("Name", classes.tableCells)}>
                Name
              </TableCell>
              <TableCell className={clsx("Code", classes.tableCells)}>
                Rate Code
              </TableCell>
              <TableCell className={clsx("Fee", classes.tableCells)}>
                Nightly Fee
              </TableCell>
              <TableCell className={clsx("Input", classes.tableCells)}>
                Access Group
              </TableCell>
              <TableCell className={clsx("Actions", classes.tableCells)}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hotelRatesList?.map((row) => {
              return (
                <TableRow
                  className={clsx("hotel-mapping-row", row.rateid)}
                  key={row.rateid}
                  data-row={row.rateid}
                  data-rate={row.name}
                >
                  <TableCell
                    className={clsx("Name", classes.tableCells)}
                    data-name={row.name}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    className={clsx("Code", classes.tableCells)}
                    data-code={row.ratecode}
                  >
                    {row.ratecode}
                  </TableCell>
                  <TableCell
                    className={clsx("Fee", classes.tableCells)}
                    data-fee={row.fee}
                  >
                    <Box>
                      <FontAwesomeIcon icon={faDollarSign} transform="left-4"/>
                      {row.fee}
                    </Box>
                  </TableCell>
                  <TableCell
                    className={clsx("AccessGroup", classes.tableCells)}
                    data-group={row.accessgroupid}
                  >
                    {getAccessGroupName(row.accessgroupid)}
                  </TableCell>
                  <TableCell
                    className={clsx("action-buttons", classes.actionTableCell)}
                  >
                    <Button
                      className={clsx("button edit")}
                      variant="outlined"
                      color="primary"
                      onClick={() => onEditClick(row.rateid)}
                    >
                      Edit
                    </Button>
                    <Button
                      className={clsx("button delete", classes.deleteButton)}
                      variant="outlined"
                      color="secondary"
                      onClick={() => onDeleteClick(row.rateid)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

HotelRatesMappingTable.defaultProps = {
  hotelRatesList: [],
  accessGroups: [],
  onEditClick: () => {},
  onDeleteClick: () => {},
};

HotelRatesMappingTable.propTypes = {
  hotelRatesList: PropTypes.array,
  accessGroups:PropTypes.array,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default React.memo(HotelRatesMappingTable);