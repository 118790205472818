import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  addGcButton: {
    marginLeft: theme.spacing(2)
  },
  itemContainer: {
    fontWeight: "bold",
    display: "inline-flex",
    width: "100%"
  },
  name: {
    width: "50%"
  },
  accountNumber: {
    width: "50%"
  },
  groupContracts: {
    width: "90%"
  }
}));
