import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 270;

export const useStyles = makeStyles(theme => ({
  toolbar: {
    "@media print": {
      display: "none",
    },
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.type === "dark" ? "#fff" : null,
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.background.level2 : null,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    overflowWrap: "break-word",
    overflow: "hidden",
    wordWrap: "break-word",
    flexWrap: "wrap",
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(4.5),
    color:theme.palette.type === "dark" ? theme.palette.text.secondary : theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0),
    }
  },
  menuButtonHidden: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  innerContainer: {
    height: "calc(100% - 64px)",
    padding: "0",
  },

  //(ノಠ益ಠ)ノ彡┻━┻
  logoBox: {
    border: "1px solid #eee",
    display: "inline-block",
    maxWidth: 300,
    minWidth: 300,
    padding: "2px 10px 0 8px",
    backgroundColor: "rgb(25, 118, 210)",
    WebkitAnimationDelay: "10s",
  },
  newLogoSvg: {
    //border:"1px solid red",
    marginLeft: -25,
    marginTop: -5,
    //marginBottom:-390,
    //marginLeft:-55,
    maxHeight: 46,
    filter:
      "invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);",
    animation: "backInLeft",
    animationDuration: "1s",

    //https://codepen.io/sosuke/pen/Pjoqqp
    //https://css-tricks.com/change-color-of-svg-on-hover/
    //our SVGs are not what we wanted.    fill and path contain color and huge padded shapes.   :(
  },

  mainContent: {
    width: "100%",
    minHeight: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  mainTag: {
    height: "100%",
  },
  textSubmitBtn: {
    float: "right",
    margin: theme.spacing(1),
  },
  profile: {
    marginLeft: 35,
    marginRight: 10,
    resize: "none",
    overflow: "hidden",
  },
  usersName: {
    overflowWrap: "break-word",
    overflow: "visible",
    wordWrap: "break-word",
    flexWrap: "wrap",
  },
  menu: {
    expanded: { width: 350 },
    collapsed: {},
  },
}));
