import React from 'react';
import { useStyles } from './style';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import useHasPermissions from '../../../hooks/useHasPermissions';

export const GroupContractsTable = ({
  groupContracts,
  onSelect,
  onDelete,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const { textConfirmation } = useConfirmationDialog();
  const { hasPermissions } = useHasPermissions();
  const groupContractsDelete = hasPermissions(['groupcontracts.delete']);

  const formatGuid = (value) => {
    return value?.substr(-12);
  };

  const handleDeleteGroupContract = async (e, id) => {
    e.stopPropagation();
    const confirmed = await textConfirmation({
      title: 'Group Contract Delete',
      message:
        'To confirm the deletion of this group contract, please type Delete Contract below',
      textConfirmation: 'Delete Contract'
    });
    if (confirmed) {
      onDelete(id);
    }
  };

  return (
    <Grid container className={clsx('group-contracts-container')}>
      <Table
        size="small"
        className={clsx('group-contract-table', classes.root)}
      >
        {!small && (
          <TableHead className={clsx('table-head')}>
            <TableRow
              className={clsx(
                'group-contract-row',
                classes.headerRow,
                classes.root
              )}
            >
              <Grid container>
                <Grid item xs={12} md={3}>
                  <TableCell
                    data-id="groupcontract-title-name"
                    data-testid="groupcontract-title-name"
                    className={clsx('name', classes.tableCell)}
                  >
                    Name
                  </TableCell>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TableCell
                    data-id="groupcontract-title-acctnumber"
                    className={clsx('status', classes.tableCell)}
                  >
                    Account Number
                  </TableCell>
                </Grid>
                <Grid item xs={12} md={6} />
              </Grid>
            </TableRow>
            <Divider />
          </TableHead>
        )}
        <TableBody className={clsx('table-body')}>
          {groupContracts?.map((row, index) => (
            <>
              <TableRow
                key={index}
                data-account-name={row.name}
                className={clsx(
                  'group-contract-row',
                  classes.clickableRow,
                  classes.root
                )}
                onClick={() => onSelect(row.contractID)}
              >
                <Grid container direction="row">
                  <Grid item xs={12} md={3}>
                    <TableCell
                      className={clsx('gc-name', classes.tableCell)}
                      data-value={row.name}
                      data-id={`name-groupcontract-${index}`}
                      data-testid={`name-groupcontract-${index}`}
                    >
                      {small ? `Name: ${row.name}` : `${row.name}`}
                    </TableCell>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TableCell
                      className={clsx('gc-account-number', classes.tableCell)}
                      data-id={`acctnumber-groupcontract-${index}`}
                      data-value={row.accountNumber ?? 'Unknown'}
                    >
                      {small
                        ? `Account #: ${formatGuid(row.accountNumber)}`
                        : formatGuid(row.accountNumber)}
                    </TableCell>
                  </Grid>
                  <Grid item xs={6} md={1}>
                    {groupContractsDelete && (
                      <Button
                        className={clsx('btn-acc-remove', classes.removeButton)}
                        data-id={`delete-groupcontract-btn-${index}`}
                        data-testid={`delete-groupcontract-btn-${index}`}
                        variant="contained"
                        color="secondary"
                        onClick={(e) =>
                          handleDeleteGroupContract(e, row.contractID)
                        }
                        startIcon={
                          small && (
                            <DeleteIcon
                              fontSize="default"
                              className={clsx(classes.icon)}
                            />
                          )
                        }
                      >
                        {!small && 'Delete'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </TableRow>
              <Divider />
            </>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

GroupContractsTable.defaultProps = {
  groupContracts: [],
  onSelect: () => {},
  onDelete: () => {},
  facilityID: undefined
};
GroupContractsTable.propTypes = {
  groupContracts: PropTypes.array,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  facilityID: PropTypes.any
};

export default GroupContractsTable;
