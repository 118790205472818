import React, { useState, useCallback } from "react";
import { Dialog, DialogContent, List } from "@material-ui/core";
import PropTypes from "prop-types";
import UserListItem from "../Users/UserListItem";
import useUserSelect from "../Users/useUserSelect";
import clsx from "clsx";
import { useStyles } from "./style";
import { USER_TYPES } from "../../../constants";
import ValidationAccountUserForm from "../../Forms/ValidationAccountUser";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import useHasPermissions from "../../../hooks/useHasPermissions";

export default function ValidationUsersList({
  disabled,
  onChange,
  selected,
  validationUsers,
  handleResend,
  isResetting,
  onDelete,
  onSubmit,
  validationAccountId
}) {
  const classes = useStyles();
  const { isChecked, onCheck } = useUserSelect({ selected });
  const [modalInfo, setModalInfo] = useState({
    open: false,
    selectedUser: {},
  });
  const currentUserID = useSelector((state) => state.user.UserID);

  const { hasPermissions } = useHasPermissions();
  const EditValidationAccountUser = hasPermissions([
    "validationaccountusers.edit",
  ]);
  const DeleteValidationAccountUser = hasPermissions([
    "validationaccountusers.delete",
  ]);
  const AddValidationAccountUser = hasPermissions([
    "validationaccountusers.add",
  ]);

  const handleEditClick = async (user) => {
    setModalInfo({ open: true, selectedUser: user });
  };

  const closeModal = () => {
    setModalInfo({ open: false, selectedUser: {} });
  };

  const handleSubmitComplete = () => {
    closeModal();
    onSubmit();
  };

  const isValidationAccountUser = useCallback((user) => {
    return user.userType == USER_TYPES.ValidationAccount;
  }, []);

  return (
    <>
      <List
        className={clsx("validation-user-list", classes.validationUserList)}
      >
        {validationUsers.map((user, index) => {
          let checked = isChecked(user.userId);
          const options = [];
          if (user.userId != currentUserID && isValidationAccountUser(user)) {
            if (EditValidationAccountUser) options.push("edit");
            if (DeleteValidationAccountUser) options.push("delete");
            if (AddValidationAccountUser) options.push("add");
          }
          return (
            <UserListItem
              key={index}
              user={user}
              checked={checked}
              disabled={disabled}
              onClick={async (userId, checked, e) => {
                onCheck(userId);
                onChange(userId, checked);
              }}
              options={options}
              editClick={handleEditClick}
              deleteClick={onDelete}
              handleResend={handleResend}
              isResetting={isResetting}
            />
          );
        })}
      </List>
      <Dialog open={modalInfo.open} className={clsx("validation-user-modal")}>
        <DialogContent>
          <ValidationAccountUserForm
            onCancel={closeModal}
            userID={modalInfo.selectedUser?.userId}
            onSubmitComplete={handleSubmitComplete}
            handleResend={handleResend}
            isResetting={isResetting}
            validationAccountId={validationAccountId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ValidationUsersList.defaultProps = {
  disabled: false,
  onChange: () => {},
  selected: [],
  validationUsers: [],
  systemOnly: false,
  onDelete: () => {},
  onSubmit: () => {},
};

ValidationUsersList.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  limit: PropTypes.number,
  validationUsers: PropTypes.arrayOf(PropTypes.object),
  systemOnly: PropTypes.bool,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
};
