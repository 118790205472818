import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Divider,
  InputAdornment,
  Box,
  Drawer,
} from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";

export const addEditSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .max(20, "Name must be 20 characters or less"),
  ratecode: Yup.string()
    .required("Required")
    .max(3, "Rate Code must be 3 characters or less"),
  fee: Yup.number()
    .typeError("Nightly Fee must be number")
    .required("Required")
    .moreThan(-1, "Nightly Fee can not be negative")
    .lessThan(100000, "Nightly Fee must be less than 100000"),
  accessgroupid: Yup.string().required("Required"),
});

const HotelRatesSideDrawer = ({
  hotelRate,
  accessGroups,
  handleSave,
  handleCancel,
  drawerOpen,
}) => {
  const classes = useStyles();

  const getAccessGroupId = () => {
    let accessGroup = { accessGroupID: "", name: "" };
    if (!hotelRate.hasOwnProperty("accessgroupid")) {
      accessGroup =
        accessGroups.length > 0 ? accessGroups[0] : { accessGroupID: "" };
    } else {
      accessGroup = accessGroups.find(
        (x) => x.accessGroupID === hotelRate.accessgroupid
      );
    }
    return accessGroup?.accessGroupID;
  };

  const updateRate = () => {
    reset({
      ...hotelRate,
      name: hotelRate.name,
      ratecode: hotelRate.ratecode,
      fee: hotelRate.fee,
      accessgroupid: hotelRate.accessgroupid,
    });
  };

  let defaults = {
    name: "",
    ratecode: "",
    fee: 0,
    accessgroupid: getAccessGroupId(),
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaults,
    resolver: yupResolver(addEditSchema),
  });

  const FormatAndSave = (values) => {
    const rateToSave = {
      rateid: values?.rateid,
      name: values.name,
      ratecode: values.ratecode,
      fee: values.fee,
      accessgroupid: values.accessgroupid,
    };
    handleSave(rateToSave);
  };

  useEffect(() => {
    if (Object.keys(hotelRate).length > 0) updateRate();
    else reset(defaults);
  }, [hotelRate]);

  return (
    <Drawer
      className={clsx(classes.drawer, "add-edit-rate-drawer")}
      anchor="right"
      open={drawerOpen}
      classes={{
        paper: classes.drawerPaper,
        root: classes.drawer,
      }}
    >
      <Grid container>
        <Grid item sm={12}>
          <Box p={1}>
            <Typography
              variant="h5"
              color="primary"
              component="h2"
              className={clsx("title", classes.DrawerTitle)}
            >
              Add / Edit
            </Typography>
          </Box>
          <Divider></Divider>
          <form
            className={classes.form}
            onSubmit={handleSubmit((values) => FormatAndSave(values))}
            noValidate
          >
            <Grid container className={clsx([classes.drawerContainer])}>
              <Grid item xs={12} className={clsx([classes.drawerItems])}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        id="name"
                        className={clsx("name-field", classes.name)}
                        label="Name *"
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} className={clsx([classes.drawerItems])}>
                <Controller
                  name="ratecode"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        id="ratecode"
                        label="Rate Code *"
                        className={clsx("ratecode", classes.flexInput)}
                        variant="outlined"
                        error={!!errors.ratecode}
                        helperText={errors.ratecode?.message}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} className={clsx([classes.drawerItems])}>
                <Controller
                  name="fee"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        id="fee"
                        label="Nightly Fee *"
                        type="number"
                        inputProps={{ min: 0, step: 0.01 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        className={clsx("fee", classes.flexInput)}
                        variant="outlined"
                        error={!!errors.fee}
                        helperText={errors.fee && errors.fee.message}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} className={clsx([classes.drawerItems])}>
                <Controller
                  name="accessgroupid"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="accessgroupid"
                      label="Access Group *"
                      select
                      error={Boolean(errors.accessgroupid)}
                      helperText={errors.accessgroupid?.message}
                      variant="outlined"
                      style={{
                        width: "100%",
                        marginBottom: "1rem",
                      }}
                      SelectProps={{
                        SelectDisplayProps: { "data-testid": "accessgroupid" },
                      }}
                    >
                      {accessGroups.map((accessGroup) => (
                        <MenuItem
                          key={accessGroup.accessGroupID}
                          value={accessGroup.accessGroupID}
                        >
                          {accessGroup.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
            <Divider></Divider>
            <Grid container className={clsx([classes.footerContainer])}>
              <Grid item xs={12} className={clsx([classes.drawerItems])}>
                <Button
                  style={{ float: "right" }}
                  startIcon={<CancelIcon />}
                  className={clsx("cancelBtn", classes.btnControl)}
                  data-id="cancelBtn"
                  name="cancel"
                  variant="contained"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ float: "right" }}
                  startIcon={<SaveIcon />}
                  color="primary"
                  data-id="saveBtn"
                  name="Save"
                  type="submit"
                  variant="contained"
                  className={clsx("saveBtn", classes.btnControl)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Drawer>
  );
};

HotelRatesSideDrawer.defaultProps = {
  hotelRate: {},
  accessGroups: [],
  handleSave: () => {},
  handleClose: () => {},
  drawerOpen: false,
};

HotelRatesSideDrawer.propTypes = {
  hotelRate: PropTypes.object,
  accessGroups: PropTypes.array,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  drawerOpen: PropTypes.bool,
};

export default React.memo(HotelRatesSideDrawer);
