import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./style";
import GroupContractInfoPanel from "../../Panels/GroupContracts/GroupContractInfo/index";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import GroupContractService from "../../../services/GroupContractService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { Dialog, DialogContent } from "@material-ui/core";
import AvailableAccessHoldersList from "../AvailableAccessHoldersList";
import AccordionPanel from "../../AccordionPanel";
import { isUndefined, isEqual } from "lodash";
import Grid from "@material-ui/core/Grid";
import { generateUUID } from "../../Rate";
import ContractAccessHoldersTable from "../ContractAccessHoldersTable";
import useContractContext from "../../../hooks/useContractContext";
import {
  clearContractFilters,
  onFiltersChanged,
} from "../../../reducers/contracts/contractReducer";
import { PaginatorLocations } from "../../PageableEntity";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import FlexibleParkingAccountPanel from "../../Panels/FlexibleParkingAccount";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import { useSelector } from "react-redux";

const groupContractService = new GroupContractService(apiClient);

function getRowsByHeight(height) {
  return Math.round(height / 130);
}

const GroupContract = ({
  contractID,
  facilityID,
  onChange,
  onDelete,
  onAccessHolderSelected,
}) => {
  const classes = useStyles();
  const [groupContract, setGroupContract] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const [isExistingContract, setIsExistingContract] = useState(false);
  const [addAccessHoldersWindow, setAddAccessHoldersWindow] = useState(false);
  const [values, dispatch] = useContractContext();
  const [accessHolders, setAccessHolders] = useState({
    totalCount: 0,
    collection: [],
  });
  const { hasPermissions } = useHasPermissions();
  const flexibleParkingAccount = useFeatureFlag("Flexible Parking Account");
  const viewGroupContract = hasPermissions(["groupcontracts.view"]);
  const viewContractAccessHolders = hasPermissions([
    "groupcontracts.view",
    "accessholders.view",
  ]);
  const editAccessHoldersAndGroupContract = hasPermissions([
    "groupcontracts.edit",
    "accessholders.edit",
  ]);
  const editAccessHolders = hasPermissions(["accessholders.edit"]);
  const groupContractEdit = hasPermissions(["groupcontracts.edit"]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);

  const handleAccessHolderWindow = () => {
    setAddAccessHoldersWindow(!addAccessHoldersWindow);
    dispatch({ type: clearContractFilters });
  };

  const handleGroupContractCreateOrUpdate = async (incomingContractChange) => {
    const { contactInfo, accountNumber, name } = incomingContractChange;
    if (isUndefined(contactInfo)) return;
    if (isEqual(contactInfo.addresses, groupContract?.contactInfo?.addresses)) {
      contactInfo.addresses = undefined;
    }
    if (isEqual(contactInfo.emails, groupContract?.contactInfo?.emails)) {
      contactInfo.emails = undefined;
    }
    if (
      isEqual(
        contactInfo.phoneNumbers,
        groupContract?.contactInfo?.phoneNumbers
      )
    ) {
      contactInfo.phoneNumbers = undefined;
    }
    groupContract.entityID = facilityGroupID || facilityID;
    groupContract.contactInfo = contactInfo;
    groupContract.accountNumber = accountNumber;
    groupContract.name = name;
    groupContract.isGroupContract = true;
    let response;
    if (isUndefined(contractID)) {
      try {
        response = await groupContractService.createGroupContract(
          groupContract
        );
      } catch (err) {
        enqueueSnackbar("Failed to create group contract", {
          variant: "error",
          tag: "groupContractCreateError",
        });
        return;
      }
    } else {
      try {
        response = await groupContractService.updateGroupContract(
          groupContract
        );
      } catch (err) {
        enqueueSnackbar("Failed to update group contract", {
          variant: "error",
          tag: "groupContractUpdateError",
        });
        return;
      }
    }
    const fullContract = {
      ...response?.data,
      contactInfo,
    };
    setGroupContract(fullContract);
    onChange(fullContract);
  };

  const handleGroupContractDelete = async (id) => {
    try {
      var result = await groupContractService.deleteGroupContract(
        facilityID,
        id
      );
      if (result?.status === 200) {
        setGroupContract({ accountNumber: generateUUID() });
        onDelete();
        return true;
      } else {
        throw result;
      }
    } catch (err) {
      if (err?.status == 412) {
        enqueueSnackbar("Access holders still assigned to group contract", {
          variant: "error",
          tag: "accessHoldersStillAssignedError",
        });
        return false;
      }
      enqueueSnackbar("Failed to delete group contract", {
        variant: "error",
        tag: "groupContractFailedToDelete",
      });
      return false;
    }
  };

  const fillAccessHolderList = useCallback(
    async (facilityID, contractID, limit, page, searchTerm) => {
      let response;
      try {
        response = await groupContractService.getGroupContractAccessHolders(
          facilityID,
          contractID,
          true, // Get the access holders already in this contract
          limit,
          page ? (page - 1) * limit : 0,
          searchTerm
        );
      } catch {
        enqueueSnackbar("Failed to retrieve access holders", {
          variant: "error",
          tag: "fetchAccessHoldersError",
        });
        return;
      }
      setAccessHolders(response?.data);
    },
    [facilityID, contractID]
  );

  const handleAddAccessHolders = async (accessHolderIDs) => {
    try {
      setAddAccessHoldersWindow(false);
      if (accessHolderIDs.length === 0) return;

      var result = await groupContractService.addAccessHoldersToGroupContract(
        facilityID,
        contractID ?? groupContract?.contractID,
        accessHolderIDs
      );
      if (result.status == 200) {
        fillAccessHolderList(
          facilityGroupID || facilityID,
          contractID ?? groupContract?.contractID,
          itemLimit,
          1,
          ""
        );
      } else {
        throw result.message;
      }
      return true;
    } catch {
      enqueueSnackbar(
        `Failed to add access holder${
          accessHolderIDs.length > 1 ? "(s)" : ""
        } to group contract`,
        {
          variant: "error",
          tag: "groupContractFailedToAddAccessHolder",
        }
      );
      return false;
    }
  };

  const handleRemoveAccessHolder = async (accessHolderID) => {
    try {
      var result = await groupContractService.removeAccessHolderFromGroupContract(
        contractID ?? groupContract?.contractID,
        accessHolderID
      );
      if (result.status == 200) {
        fillAccessHolderList(
          facilityGroupID || facilityID,
          contractID ?? groupContract?.contractID,
          itemLimit,
          values.currentContractAccessHoldersPage,
          ""
        );
      } else {
        throw result.message;
      }
      return true;
    } catch {
      enqueueSnackbar(`Failed to remove access holder from group contract`, {
        variant: "error",
        tag: "groupContractFailedToRemoveAccessHolder",
      });
      return false;
    }
  };

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  const handlePageChange = (e, page) => {
    dispatch({
      type: onFiltersChanged,
      payload: { currentContractAccessHoldersPage: page },
    });
  };

  useEffect(() => {
    if (contractID || groupContract?.contractID) {
      fillAccessHolderList(
        facilityGroupID || facilityID,
        contractID ?? groupContract?.contractID,
        itemLimit,
        values.currentContractAccessHoldersPage,
        searchTerm
      );
    }
  }, [searchTerm, contractID, groupContract?.contractID, values.currentContractAccessHoldersPage, facilityID, fillAccessHolderList, itemLimit]);

  useEffect(() => {
    const fetchGroupContract = async (contractID) => {  
      try {
        const response = await groupContractService.getGroupContract(
          facilityID,
          contractID
        );
  
        setGroupContract(response.data);
        setIsExistingContract(true);
      } catch (err) {
        enqueueSnackbar("Failed to retrieve group contract", {
          variant: "error",
          tag: "groupContractFetchError",
        });
        setIsExistingContract(false);
      }
    };

    if (contractID) {
      fetchGroupContract(contractID);
    } else {
      setGroupContract({ accountNumber: generateUUID() });
    }
  }, [contractID]);

  useEffect(() => {
    if (!isExistingContract && groupContract?.contractID != undefined)
      setIsExistingContract(true);
  }, [groupContract]);

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  return (
    <Grid container className={clsx("groupContract-form-root", classes.root)}>
      <GroupContractInfoPanel
        className={clsx(classes.GroupContractForm, classes.flexChild)}
        groupContract={groupContract}
        onChange={handleGroupContractCreateOrUpdate}
        onDelete={handleGroupContractDelete}
        accessHolderCount={accessHolders?.totalCount ?? 0}
      />
      {flexibleParkingAccount &&
        (!isUndefined(contractID) ||
          !isUndefined(groupContract?.contractID)) && (
          <FlexibleParkingAccountPanel
            className={clsx(classes.flexChild)}
            disabled={!groupContractEdit}
            facilityID={facilityID}
            contractId={contractID ?? groupContract?.contractID}
          viewPermission={viewGroupContract}
          />
        )}
      {(!isUndefined(contractID) || !isUndefined(groupContract?.contractID)) &&
        viewContractAccessHolders && (
          <AccordionPanel
            title="Contract Access Holders"
            mainPermission={viewContractAccessHolders}
            hideAddBtn={!editAccessHoldersAndGroupContract}
            searchBarPlaceHolder="Search by Name, Credential Reference #, license plate, vehicle make, or vehicle model"
            searchBarLabel="Contract Access Holders"
            onSearchChange={handleSearchChange}
            defaultSearch={values.searchTerm}
            addButtonLabel="Add Access Holders"
            addButtonClicked={handleAccessHolderWindow}
            addButtonDisabled={!editAccessHolders}
            onPageChange={handlePageChange}
            currentPage={values.currentContractAccessHoldersPage ?? 1}
            paginatorLocation={PaginatorLocations.TOP}
            totalCount={Math.ceil(accessHolders.totalCount / itemLimit)}
            details={
              <ContractAccessHoldersTable
                accessHolders={accessHolders.collection}
                onSelect={onAccessHolderSelected}
                facilityID={facilityID}
                onDelete={handleRemoveAccessHolder}
                contractID
              />
            }
          />
        )}
      <Dialog
        open={addAccessHoldersWindow}
        onClose={handleAccessHolderWindow}
        classes={{ paper: classes.modal }}
      >
        <DialogContent>
          {editAccessHoldersAndGroupContract && (
            <AvailableAccessHoldersList
              onAddClick={(ids) => handleAddAccessHolders(ids)}
              facilityID={facilityID}
              contractID={contractID ?? groupContract?.contractID}
              hideFilterBtn={true}
            />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

GroupContract.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
};

GroupContract.propTypes = {
  contractID: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default GroupContract;
