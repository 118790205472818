import React from "react";
import PropTypes from 'prop-types';
import Grow from "@material-ui/core/Grow";
import clsx from "clsx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import HotelRatesConfiguration from "./HotelRatesConfiguration";

const DetailTransition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={true} ref={ref} {...props} />;
});

const HotelRatesExpandedView = ({
  entityID,
  hotelInterfaceSettings,
  onClose,
}) => {
  const [showSettings, setShowSettings] = React.useState(true);
  const closeExpanded = () => {
    setShowSettings(false);
    onClose();
  };

  return (
    <>
      <Dialog
        fullScreen={true}
        TransitionComponent={DetailTransition}
        scroll="paper"
        open={showSettings}
        className={clsx("hotelrate-mappings-dialog")}
      >
        <DialogContent dividers={true}>
          <HotelRatesConfiguration
            deviceID={entityID}
            hotelInterfaceSettings={hotelInterfaceSettings}
          />
        </DialogContent>
        <DialogActions style={{ display: "flex" }}>
          <Button
            onClick={closeExpanded}
            color="primary"
            className={clsx(["btn-close-detail"])}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

HotelRatesExpandedView.defaultProps = {
  hotelInterfaceSettings: "{}",
  onClose: () => { },
};

HotelRatesExpandedView.propTypes = {
  entityID: PropTypes.string,
  hotelInterfaceSettings:PropTypes.string,
  onClose: PropTypes.func,
};

export default React.memo(HotelRatesExpandedView);