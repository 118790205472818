import React, { useState, useCallback } from "react";
import apiClient from "../../auth/apiClient";
import { useSelector } from "react-redux";
import ImportService from "../../services/ImportService";
import { DropzoneArea } from "material-ui-dropzone";
import {
  Button,
  Typography,
  Grid,
  Box,
  Link,
  Card,
  CardContent,
} from "@material-ui/core";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useStyles } from "./styles";
import { isUndefined } from "lodash";
import ImportHistoryTable from "./ImportHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

const importService = new ImportService(apiClient);

const Import = () => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const currentUserId = useSelector(state => state.user?.UserID);
  const facilityId = useSelector(state => state.entities?.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityId);
  const [historyData, setHistoryData] = useState([]);
  const [showHistoryError, setShowHistoryError] = useState(false);
  const [isProcessing, setIsProcressing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [file, setFile] = useState();
  const [displayResponse, setDisplayResponse] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const fetchHistory = useCallback(async () => {
    try {
      let response = await importService.getImportsByFacilityId(scopeAwareFacilityID);
      return response.data;
    } catch {
      return undefined;
    }
  });

  const handleFetchHistory = () => {
    setIsFetching(true);
    fetchHistory().then(history => {
      if (isUndefined(history)) {
        setShowHistoryError(true);
      } else {
        setHistoryData(history);
        setShowHistoryError(false);
      }
    });
    setIsFetching(false);
  };

  const handleAddFile = e => {
    if (e.length === 0) return;
    setShowBtn(true);
    setFile(e[0]);
    setErrorMessages([]);
  };
  const handleDeleteFile = () => {
    setFile(undefined);
    setShowBtn(false);
    setErrorMessages([]);
  };

  const handleSubmit = async () => {
    setIsProcressing(true);

    try {
      var response = await importService.uploadFile(
        currentUserId,
        file,
        scopeAwareFacilityID
      );
      if (response.status === 200) {
        enqueueSnackbar("Successfully uploaded file to server", {
          variant: "success",
          tag: "uploadFileSuccess",
        });
      }
    } catch (err) {
      if (!err.response?.data) {
        enqueueSnackbar("Failed uploaded file to server. Please remove import file and retry", {
          variant: "error",
          tag: "uploadFileSuccess",
        });
        setErrorMessages([]);
      } else {
        setErrorMessages(err.response.data.errorMessages);
      }
    }

    setIsProcressing(false);
    setDisplayResponse(true);
  };

  return (
    <Grid container>
      <Grid item>
        <Typography className={classes.title} variant="h5" color="primary">
          Access Holder Import
        </Typography>
      </Grid>
      <Grid item className={classes.title}>
        <Typography>
          This is a one time import that allows you to add access holders to the
          system in bulk. Please use this{" "}
          <Link
            underline="always"
            href={process.env.PUBLIC_URL + "/files/Amano_ONE_sample_import.csv"}
          >
            Sample CSV
          </Link>{" "}
          as an example of how to format your import file.
        </Typography>
      </Grid>
      <Grid item className={classes.dropboxContainer}>
        <DropzoneArea
          className={classes.previewChip}
          acceptedFiles={[
            ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
          ]}
          data-testid="dropzone"
          filesLimit={1}
          type="file"
          onChange={handleAddFile}
          onDelete={handleDeleteFile}
        />
        {showBtn && (
          <Button
            className={classes.submitBtn}
            variant="contained"
            color="primary"
            id="submitFileBtn"
            disabled={isProcessing}
            onClick={handleSubmit}
          >
            {isProcessing ? (
              <FontAwesomeIcon
                className={classes.icon}
                icon={faSpinner}
                pulse
                size="lg"
              />
            ) : (
              "Submit"
            )}
          </Button>
        )}
        {displayResponse && (
          <Grid item>
            {errorMessages && (
              <Card className={classes.errorBox} id="errorBox">
                {errorMessages.map((message, index) => (
                  <CardContent className={classes.error} key={index}>
                    {message}
                  </CardContent>
                ))}
              </Card>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.gridRow}>
        {historyData.length !== 0 && (
          <Typography className={classes.title} variant="h6" color="primary">
            History
          </Typography>
        )}
        <Button
          className={classes.getHistoryBtn}
          variant="outlined"
          color="primary"
          onClick={handleFetchHistory}
          disabled={isFetching}
        >
          Get History
        </Button>
      </Grid>
      {showHistoryError && (
        <Grid item className={classes.errorBox}>
          <Card>
            <CardContent className={classes.error}>
              No import history found.
            </CardContent>
          </Card>
        </Grid>
      )}
      {historyData.length !== 0 && (
        <Grid item className={classes.tableContainer}>
          <ImportHistoryTable rows={historyData} />
        </Grid>
      )}
    </Grid>
  );
};

export default Import;
