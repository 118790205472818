import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Grow from "@material-ui/core/Grow";
import PropTypes from "prop-types";
import clsx from "clsx";
import _ from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import GPIOConfiguration from "./GPIOConfiguration";

const DetailTransition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={true} ref={ref} {...props} />;
});

const GPIOExpandedView = ({ entityID, onClose }) => {
  const entities = useSelector(state => state.entities, shallowEqual);
  const [showSettings, setShowSettings] = React.useState(true);

  const closeExpanded = () => {
    setShowSettings(false);
    onClose();
  };
  const openExpanded = () => {
    setShowSettings(true);
  };

  return (
    <>
      <Dialog
        fullScreen={true}
        TransitionComponent={DetailTransition}
        scroll="paper"
        open={showSettings}
        className={clsx("gpio-mappings-dialog")}
      >
        <DialogContent dividers={true}>
          <GPIOConfiguration 
          deviceID={entityID}
           />
        </DialogContent>
        <DialogActions style={{ display: "flex" }}>
          <Button
            onClick={closeExpanded}
            color="primary"
            className={clsx(["btn-close-detail"])}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

GPIOExpandedView.defaultProps = { entityID: null, tiled: true };
GPIOExpandedView.propTypes = { entityID: PropTypes.string, tiled: PropTypes.bool };

export default GPIOExpandedView;
