import React, { useState, useEffect, useReducer } from "react";
import { useStyles } from "./styles";
import ListView from "../ListView/ListView";
import ThirdpartyVendorService from "../../services/ThirdpartyVendorService";
import ThirdPartyVendorConfigurationForm from "../Forms/ThirdPartyVendors/index";
import apiClient from "../../auth/apiClient";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const defaultIntegratorValues = {
  subscriberID: "",
  description: "",
  status: true,
  contacts: [
    {
      firstName: "",
      lastName: "",
      addresses: [
        {
          addressLine1: "",
          addressLine2: "",
          state: "",
          postalCode: "",
          city: ""
        }
      ],
      phoneNumbers: [
        {
          number: ""
        }
      ],
      emails: [
        {
          emailAddress: ""
        }
      ]
    },
    {
      firstName: "",
      lastName: "",
      addresses: [
        {
          addressLine1: "",
          addressLine2: "",
          state: "",
          postalCode: "",
          city: ""
        }
      ],
      phoneNumbers: [
        {
          number: ""
        }
      ],
      emails: [
        {
          emailAddress: ""
        }
      ]
    }
  ]
};

const initialIntegratorState = {
  formOpen: false,
  integrator: defaultIntegratorValues
};
const integratorReducer = (state, { type, payload }) => {
  switch (type) {
    case "ADD_INTEGRATOR":
      return {
        ...state,
        integrator: defaultIntegratorValues,
        formOpen: true
      };
    case "EDIT_INTEGRATOR":
      return { ...state, integrator: payload.integrator, formOpen: true };
    case "CLOSE_FORM":
      return { ...state, integrator: undefined, formOpen: false };
    default:
      return state;
  }
};

const thirdPartyVendorService = new ThirdpartyVendorService(apiClient);

const IntegratorSetup = ({ entityID }) => {
  const classes = useStyles();
  const location = useLocation();
  const [subscriberData, setSubscriberData] = useState([]);
  const isAdmin = useSelector(state => state?.user?.isAdmin);
  const [state, dispatch] = useReducer(
    integratorReducer,
    initialIntegratorState
  );
  let mappedDataForListView = [];

  const handleCreated = createdIntegrator => {
    let list = subscriberData;
    createdIntegrator.activeAPICount = 1;
    list.push(createdIntegrator);
    setSubscriberData(list);
  };

  const handleUpdated = updatedIntegrator => {
    let list = subscriberData;
    let index = list.findIndex(
      x => x.subscriberID == updatedIntegrator.subscriberID
    );
    list[index] = updatedIntegrator;
    setSubscriberData(list);
  };

  const handleAddIntegrator = () => {
    dispatch({ type: "ADD_INTEGRATOR" });
  };

  const handleEditIntegrator = id => {
    let index = subscriberData?.findIndex(x => x.subscriberID == id);
    if (index >= 0) {
      dispatch({
        type: "EDIT_INTEGRATOR",
        payload: { integrator: subscriberData[index] }
      });
    } else {
      dispatch({ type: "CLOSE_FORM" });
    }
  };

  const handleCancel = () => {
    dispatch({ type: "CLOSE_FORM" });
  };

  const getMappedData = () => {
    if (subscriberData?.length > 0) {
      subscriberData.map(row => {
        mappedDataForListView.push({
          name: row.description,
          status: row.activeAPICount > 0 ? "Active" : "Inactive",
          id: row.subscriberID
        });
      });
    }

    return mappedDataForListView;
  };

  const getSubscribers = async () => {
    try {
      let result = await thirdPartyVendorService.getThirdPartyIntegrators(
        entityID
      );
      setSubscriberData(result.data);
    } catch (error) {
      setSubscriberData([]);
    }
  };

  useEffect(() => {
    getSubscribers();
  }, [entityID]);

  useEffect(() => {
    if (location.pathname == "/integratorsetup")
      dispatch({ type: "CLOSE_FORM" });
  }, [location]);

  if (!isAdmin) return <></>;

  return (
    <>
      {!state.formOpen ? (
        <ListView
          handleAddButton={handleAddIntegrator}
          handleEditButton={handleEditIntegrator}
          deleteButtonToggle={false}
          editButtonToggle={true}
          titleBarTitleText="Integrators"
          titleBarToggle={true}
          titleBarTextToggle={true}
          titleBarAddButtonToggle={true}
          addButtonText=""
          showTableHead={true}
          paginationToggle={true}
          data={getMappedData()}
          editButtonText="Edit"
          sortCaseInsensitive={true}
        />
      ) : (
        <ThirdPartyVendorConfigurationForm
          integratorInfo={state.integrator}
          handleCreated={handleCreated}
          handleUpdated={handleUpdated}
          editPermission={isAdmin}
          onGoBackClick={handleCancel}
        />
      )}
    </>
  );
};

export default IntegratorSetup;
