import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "./styles";
import Tooltip from '@material-ui/core/Tooltip';

function GatewayDropDown({
  gateways,
  onChange,
  gatewaySettings,
  isSelectDisabled
}) {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState({
    id: "",
    name: "",
  });

  useEffect(() => {
    if (gateways && gateways.length >= 1) {
      setSelectedItem({
        id: gateways[0].creditCardGatewayID,
        name: gateways[0].gatewayName,
      });
    }
  }, [gateways]);

  useEffect(() => {
    if (selectedItem && selectedItem.name) {
      onChange(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (gatewaySettings && gateways && gatewaySettings.length > 0) {
      let savedSettings = gatewaySettings.reduce((acc, val) => {
        if (val.active) {
          return acc.concat(val)
        } else {
          return acc
        }
      }, []).sort((a, b) => a.settingLevel - b.settingLevel).at(0).setting;  // If facility settings override facility group settings, use them
      let currentlyConfiguredGateway = gateways.find((row) =>
        savedSettings.includes(row.gatewayName)
      );
      if (currentlyConfiguredGateway != null) {
        setSelectedItem({
          id: currentlyConfiguredGateway.creditCardGatewayID,
          name: currentlyConfiguredGateway.gatewayName,
        });
      }
    }
  }, [gatewaySettings, gateways]);

  return (
    <Tooltip title={isSelectDisabled? "Cannot update the gateway if device is armed" : ""} placement="top-end">
      <FormControl className={classes.formControl} variant="outlined" disabled={isSelectDisabled}>
        <InputLabel id="gateway-label">Gateway</InputLabel>
        {gateways && (
          <Select
            value={selectedItem.name}
            labelId="gateway-label" 
            label="Gateway"
            data-id="GatewaySelect"
            onChange={(e, child) => {
              setSelectedItem({
                id: child.props.id,
                name: e.target.value,
              });
            }}
          >
            {gateways.map((row) => (
              <MenuItem
                key={row.creditCardGatewayID}
                value={row.gatewayName}
                id={row.creditCardGatewayID}
              >
                {row.gatewayName}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Tooltip>
  );
}

GatewayDropDown.defaultProps = {
  isSelectDisabled : false
};

export default GatewayDropDown;


