import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { PaginatorLocations } from "../../PageableEntity";
import { Grid, LinearProgress } from "@material-ui/core";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { onFiltersChanged } from "../../../reducers/contracts/contractReducer";
import GroupContractService from "../../../services/GroupContractService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import GroupContractFilters, {
  SORT_DIRECTION,
} from "../../Filters/GroupContractFilters";
import useContractContext from "../../../hooks/useContractContext";
import GroupContractsTable from "../GroupContractsTable";
import { useStyles } from "./styles";
import AccordionPanel from "../../AccordionPanel";
import { useSelector } from "react-redux";

const groupContractService = new GroupContractService(apiClient);

function getRowsByHeight(height) {
  return Math.round(height / 130);
}

const GroupContractList = ({ onSelect, onAddClick }) => {
  const { facilityID } = useCurrentFacility();
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const groupContractsAdd = hasPermissions(["groupcontracts.add"]);
  const groupContractsView = hasPermissions(["groupcontracts.view"]);
  const [values, dispatch] = useContractContext();
  const classes = useStyles();
  const [groupContracts, setGroupContracts] = useState({
    totalCount: 0,
    collection: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  useEffect(() => {
    fillGroupContractList(
      facilityGroupID || facilityID,
      itemLimit,
      values.groupContractsPage,
      values.searchTerm,
      values.nameSortDirection,
      values.acctnumberSortDirection
    );
  }, [facilityID, facilityGroupID, values.searchTerm, values.groupContractsPage]);

  const sortByHelper = (sortDirection, propertyToSortBy) => {
    switch (sortDirection) {
      case SORT_DIRECTION.ASC: {
        return (obj1, obj2) =>
          obj1[propertyToSortBy] > obj2[propertyToSortBy] ? true : -1;
      }
      case SORT_DIRECTION.DESC: {
        return (obj1, obj2) =>
          obj1[propertyToSortBy] < obj2[propertyToSortBy] ? true : -1;
      }
      default: {
        return (obj1, obj2) => null;
      }
    }
  };

  const sortGroupContracts = (
    contracts,
    nameSortDirection,
    acctnumberSortDirection
  ) => {
    const nameSort = sortByHelper(nameSortDirection, "name");
    const acctnumberSort = sortByHelper(
      acctnumberSortDirection,
      "accountNumber"
    );

    contracts.sort((a, b) => {
      const nameComparison = nameSort(a, b);
      if (nameComparison !== 0) return nameComparison;
  
      // Check if accountNumber exists before comparing
      if (a.accountNumber && b.accountNumber) {
        return acctnumberSort(a, b);
      } else if (a.accountNumber) {
        return -1;
      } else if (b.accountNumber) {
        return 1;
      } else {
        return 0;
      }
    });

    setGroupContracts((prev) => ({
      ...prev,
      collection: contracts,
    }));
  };

  const sortBasedOnFilter = (
    contracts,
    nameSortDirection,
    acctnumberSortDirection
  ) => {
    sortGroupContracts(contracts, nameSortDirection, acctnumberSortDirection);
    if (
      nameSortDirection === SORT_DIRECTION.NONE &&
      acctnumberSortDirection === SORT_DIRECTION.NONE
    ) {
      fillGroupContractList(
        facilityGroupID || facilityID,
        itemLimit,
        values.groupContractsPage,
        values.searchTerm,
        SORT_DIRECTION.NONE,
        SORT_DIRECTION.NONE
      );
    }
  };

  useEffect(() => {
    const current = groupContracts?.collection ?? [];
    sortBasedOnFilter(
      current,
      values.nameSortDirection,
      values.acctnumberSortDirection
    );
  }, [values.nameSortDirection, values.acctnumberSortDirection]);

  const fillGroupContractList = useCallback(
    async (
      facilityID,
      limit,
      page,
      searchTerm,
      nameSortDirection,
      acctnumberSortDirection
    ) => {
      let response;
      try {
        setIsLoading(true);
        response = await groupContractService.getGroupContracts(
          facilityID,
          limit,
          page ? (page - 1) * limit : 0,
          searchTerm
        );
      } catch {
        setIsLoading(false);
        enqueueSnackbar("Failed to retrieve group contracts", {
          variant: "error",
          tag: "fetchGroupContractsError",
        });
        return;
      }
      const listOfContracts = response?.data?.collection;
      setGroupContracts({
        totalCount: response?.data?.totalCount,
      });
      sortGroupContracts(
        listOfContracts,
        nameSortDirection,
        acctnumberSortDirection
      );
      setIsLoading(false);
    },
    [facilityID, itemLimit, values.groupContractsPage, values.searchTerm]
  );

  const handleRemoveGroupContract = async (id) => {
    try {
      var result = await groupContractService.deleteGroupContract(
        facilityID,
        id
      );
      if (result?.status === 200) {
        var newCollection = groupContracts.collection.filter(
          (x) => x.contractID != id
        );
        var newCount = groupContracts.totalCount - 1;
        setGroupContracts({
          totalCount: newCount,
          collection: newCollection,
        });
      } else {
        throw result;
      }
      return true;
    } catch (err) {
      if (err?.response?.status == 412) {
        enqueueSnackbar("Access holders still assigned to group contract", {
          variant: "error",
          tag: "accessHoldersStillAssignedError",
        });
        return false;
      }
      enqueueSnackbar("Failed to delete group contract", {
        variant: "error",
        tag: "groupContractFailedToDelete",
      });
      return false;
    }
  };

  const handleAddGroupContract = () => {
    onAddClick();
  };

  const handleGroupContractClick = (contractID) => {
    onSelect(contractID);
  };

  const handlePageChange = (e, page) => {
    dispatch({
      type: onFiltersChanged,
      payload: { groupContractsPage: page },
    });
  };

  const handleSearchChange = (term) => {
    dispatch({
      type: onFiltersChanged,
      payload: { searchTerm: term },
    });
  };

  return (
    <Grid
      container
      className={clsx("group-contracts-container", classes.groupContracts)}
    >
      <AccordionPanel
        title="Group Contracts"
        data-testid="group-contracts-panel"
        filterComponent={<GroupContractFilters />}
        mainPermission={groupContractsView}
        hideAddBtn={!groupContractsAdd}
        searchBarPlaceHolder="Search by Name or AccountNumber"
        searchBarLabel="Group Contracts"
        onSearchChange={handleSearchChange}
        defaultSearch={values.searchTerm}
        addButtonLabel="Add Group Contract"
        addButtonClicked={handleAddGroupContract}
        onPageChange={handlePageChange}
        currentPage={values.groupContractsPage ?? 1}
        paginatorLocation={PaginatorLocations.TOP}
        totalCount={Math.ceil(groupContracts?.totalCount / itemLimit)}
        details={
          <>
              {isLoading && <LinearProgress style={{ width: "100%", opacity: 0.5 }} />}

              {!isLoading &&
                  <GroupContractsTable
                  groupContracts={groupContracts?.collection ?? []}
                  onSelect={handleGroupContractClick}
                  onDelete={handleRemoveGroupContract}
                  facilityID={facilityID}
                />
              }
          </>       
        }
      />
    </Grid>
  );
};

GroupContractList.defaultProps = {
  onSelect: () => {},
  onAddClick: () => {},
};

GroupContractList.propTypes = {
  onSelect: PropTypes.func,
  onAddClick: PropTypes.func,
};

export default GroupContractList;
