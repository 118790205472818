import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { RemoveRedEye } from "@material-ui/icons";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useGatewayContext } from "../../providers/CreditCardGatewayProvider";
import CreditCardOnFileService from "../../services/CreditCardOnFileService";
import { TextField } from "formik-material-ui";
import { useStyles } from "./styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useGatewayFormUtilities, {
  GATEWAY_TYPES,
} from "./useGatewayFormUtilities";
import { useFlags } from "launchdarkly-react-client-sdk";
import _ from "lodash";
import SettingsResetButton from "../Forms/Settings/SettingsResetButton";
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';

function GatewaySettings({
  entityType,
  disabled,
  onClose,
  gatewaySettings,
  gatewaySettingsParent,
  gatewayDropdownValue,
  onSave,
  facilityID,
}) {
  const enqueueSnackbar = useEnqueueSnackbar();
  const classes = useStyles();
  const { functions: { upsertSettings } } = useGatewayContext();
  const [hitAPIKeyToggle, setHITAPIKeyToggle] = useState(false);
  const creditCardOnFileService = new CreditCardOnFileService(apiClient);
  const { textConfirmation } = useConfirmationDialog();
  const { eCommerceSplitApi } = useFlags();
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);
  ///Gateway settings are applicable for entities(i.e., children under FG)of type: Facility and ValetArea.
  ///But Need to impose functional variations only for child entities of type:facility alone.
  //So included entity type check too.
  const isChildFacility = !_.isNil(facilityGroupId) && facilityID != facilityGroupId && entityType?.toLowerCase() === "facility";
  const [creditCardGatewaySetting, setCreditCardGatewaySetting] = useState({
    //primary key
    ccGatewayEntityID: "",
    setting: "windcave",
    active: true,
    value: {
      currency: "USD",
      gateway: "",
      RestAPIUser: "",
      RestAPIKey: "",
      FlexAPIUser: "",
      FlexAPIKey: "",
      timeout: 30,
      HITUser: "",
      HITKey: "",
      RequesterLocationID: ""
    },
  });
  const [creditCardGatewaySettingParent, setCreditCardGatewaySettingParent] = useState({
    //primary key
    ccGatewayEntityID: "",
    setting: "windcave",
    active: true,
    value: {
      currency: "USD",
      gateway: "",
      RestAPIUser: "",
      RestAPIKey: "",
      FlexAPIUser: "",
      FlexAPIKey: "",
      timeout: 30,
      HITUser: "",
      HITKey: "",
      RequesterLocationID: ""
    },
  });

  const { canRenderField } = useGatewayFormUtilities(
    entityType,
    gatewayDropdownValue.creditCardGatewayID === "Windcave" ? GATEWAY_TYPES.WINDCAVE : GATEWAY_TYPES.PLANET
  );

  const [RestAPIKeyToggle, setRestAPIKeyToggle] = useState({
    passwordIsMasked: false,
  });
  
  const toggleRestAPIKey = () => {
    if (RestAPIKeyToggle.passwordIsMasked === true) {
      setRestAPIKeyToggle({ ...RestAPIKeyToggle, passwordIsMasked: false });
    } else {
      setRestAPIKeyToggle({ ...RestAPIKeyToggle, passwordIsMasked: true });
    }
  };

  const [FlexAPIKeyToggle, setFlexAPIKeyToggle] = useState({
    passwordIsMasked: false,
  });
  
  const toggleFlexAPIKey = () => {
    if (FlexAPIKeyToggle.passwordIsMasked === true) {
      setFlexAPIKeyToggle({ ...FlexAPIKeyToggle, passwordIsMasked: false });
    } else {
      setFlexAPIKeyToggle({ ...FlexAPIKeyToggle, passwordIsMasked: true });
    }
  };
  
  const toggleHITAPIKey = () => {
    setHITAPIKeyToggle(currentToggle => !currentToggle); 
  };

  useEffect(() => {
    let existing = gatewaySettings.find((row) =>
        row.active
    );

    if (existing != null) {
      setCreditCardGatewaySetting(existing);
    }

    let existingParent = gatewaySettingsParent?.find(
      (row) =>
      row.active && (
        row.setting?.toLowerCase() === "windcave" ||
        row.setting?.toLowerCase() === "wind cave" ||
        row.setting?.toLowerCase() === "planet"
     ) 
    );

    if (existingParent != null) {
      setCreditCardGatewaySettingParent(existingParent);
    }
  }, [gatewaySettings, gatewaySettingsParent]);

  useEffect(() => {
    let existing = gatewaySettings.find((row) =>
        row.setting === gatewayDropdownValue.creditCardGatewayID
    );

    if (existing != null) {
      setCreditCardGatewaySetting(existing);
    }
  }, [gatewayDropdownValue]);

  const SaveEntitySetting = async (values) => {
    let gatewaySetting = creditCardGatewaySetting;

    const {
      timeout,
      currency,
      RestAPIUser,
      RestAPIKey,
      FlexAPIUser,
      FlexAPIKey,
      HITUser,
      HITKey,
      RequesterLocationID
    } = values;
    gatewaySetting.value = {
      timeout,
      currency,
      RestAPIUser,
      RestAPIKey,
      FlexAPIUser,
      FlexAPIKey,
      HITUser,
      HITKey,
      RequesterLocationID
    };

    if (gatewaySetting.setting != gatewayDropdownValue.creditCardGatewayID)
      gatewaySetting.active = false;

    var gatewayChanged = !gatewaySetting.active;

    gatewaySetting.setting = gatewayDropdownValue.creditCardGatewayID;
    gatewaySetting.value.RequesterLocationID = gatewayDropdownValue.creditCardGatewayID === "Windcave" ? ''
      : gatewaySetting.value.RequesterLocationID.replace(/\s+/gi, '');

    if (gatewayChanged) {
      let confirmed = await textConfirmation({
        title: 'Confirm Credit Card Gateway Change',
        message: 'Changing the gateway will result in disabling the credit card hardware associated to it and remove any and all credit cards on file tokens.  To confirm the change, please type UPDATE in the box below.',
        textConfirmation: 'UPDATE'
      });
      if (confirmed) {
        try {
          await creditCardOnFileService.deleteTokens(facilityID);
        } catch (err) {
          console.error(err);
        }
      } else {
        return;
      }
    }

    try {
      await upsertSettings(facilityID, gatewaySetting);
      enqueueSnackbar("Successfully updated gateway settings", {
        variant: "success",
      });
      onSave();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to update gateway settings", {
        variant: "error", tag: "FailedToUpdateGatewaySettings"
      });
    }
  };

  const GatewaySchema = Yup.object().shape({
    timeout: Yup.string()
      .notRequired()
      .when("showTimeout", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
    currency: Yup.string()
      .notRequired()
      .when("showCurrency", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
    RestAPIUser: Yup.string()
      .notRequired()
      .when("showRestAPIUser", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
    RestAPIKey: Yup.string()
      .notRequired()
      .when("showRestAPIKey", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
    FlexAPIUser: Yup.string()
      .notRequired()
      .when("showFlexAPIUser", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
    FlexAPIKey: Yup.string()
      .notRequired()
      .when("showFlexAPIKey", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }),
    HITUser: Yup.string().ensure()
      .when("HITKey", {
        is: "",
        then: Yup.string().optional(),
        otherwise: Yup.string().required("Required")
      }),
    HITKey: Yup.string().ensure()
      .when("HITUser", {
        is: "",
        then: Yup.string().optional(),
        otherwise: Yup.string().required("Required")
      }),
      RequesterLocationID: Yup.string()
      .notRequired()
      .when("showRequesterLocationID", {
        is: true,
        then: Yup.string().trim("No leading or trailing whitespaces...").required("Required").max(10,"Must be no more than 10"),
        otherwise: Yup.string().notRequired(),
      }),
  }, ['HITUser', 'HITKey']);

  return (
    <Formik
      onSubmit={(e) => {
        SaveEntitySetting(e);
      }}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur
      initialValues={{
        ...creditCardGatewaySetting.value,
        showTimeout: canRenderField("timeout"),
        showCurrency: canRenderField("currency"),
        showRestAPIUser: canRenderField("RestAPIUser"),
        showRestAPIKey: canRenderField("RestAPIKey"),
        showFlexAPIUser: canRenderField("FlexAPIUser") && eCommerceSplitApi && !isChildFacility,
        showFlexAPIKey: canRenderField("FlexAPIKey") && eCommerceSplitApi && !isChildFacility,
        showHITUser: canRenderField("HITUser") && !isChildFacility,
        showHITKey: canRenderField("HITKey") && !isChildFacility,
        showRequesterLocationID: canRenderField("RequesterLocationID"),
      }}
      validationSchema={GatewaySchema}
    >
      {({ submitForm, values, setFieldValue }) => (
        <Form>
          {canRenderField("currency") && (
            <Grid item>
              <Field
                select
                variant="outlined"
                component={TextField}
                name="currency"
                label="currency"
                className={classes.formControl}
                disabled={disabled}
                InputProps={{
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  disabled: Boolean(disabled),
                }}
              >
                <MenuItem value="CAD">CAD</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Field>
            </Grid>
          )}
          {canRenderField("timeout") && (
            <Grid item>
              <Field
                component={TextField}
                data-id="timeout"
                variant="outlined"
                label="Timeout (seconds)"
                className={classes.formControl}
                name="timeout"
                disabled={disabled}
                InputProps={{
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  disabled: Boolean(disabled),
                }}
              />
            </Grid>
          )}
          {canRenderField("RequesterLocationID") && (
            <Tooltip placement="top-end" title="This determines the location ID of the facility which dictates the country/currency for credit card transactions and is provided by Planet during merchant setup.">
              <Grid item>
                <Field
                  component={TextField}
                  data-id="RequesterLocationID" 
                  variant="outlined"
                  label="Requester Location ID"
                  className={classes.formControl}
                  name="RequesterLocationID"
                  disabled={disabled}
                  InputProps={{
                    readOnly: Boolean(disabled),
                    "aria-readonly": Boolean(disabled),
                    disabled: Boolean(disabled),
                  }}
                  inputProps={{
                    "aria-label": "requesterlocationid"
                  }}
                />
              </Grid>
            </Tooltip>
          )}
          {(canRenderField("RestAPIUser") && canRenderField("RestAPIKey")) && (
            <Grid item xs={12}>
                <h2>{gatewayDropdownValue.creditCardGatewayID === "Windcave" ? "Mobile Pay eCommerce Credentials": "Web2Pay Credentials"}</h2>
            </Grid>
          )}
          {canRenderField("RestAPIUser") && (
            <Grid item>
              <Field
                component={TextField}
                data-id="RestAPIUser"
                variant="outlined"
                label={
                  gatewayDropdownValue.creditCardGatewayID === "Windcave" ? "API User" : "eMerchant ID"
                }
                className={classes.formControl}
                name="RestAPIUser"
                role="RestAPIUser"
                disabled={disabled}
                InputProps={{
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  disabled: Boolean(disabled),
                  "data-testid": "RestAPIUser"
                }}
              />
              <SettingsResetButton
                entityID={facilityID}
                entityType={entityType}
                settingName="RestAPIUser"
                currentValue={values.RestAPIUser}
                originalValue={creditCardGatewaySettingParent.value.RestAPIUser}
                onClick={() => {
                  setFieldValue(
                    "RestAPIUser",
                    creditCardGatewaySettingParent.value.RestAPIUser
                  );
                }}
              />
            </Grid>
          )}
          {canRenderField("RestAPIKey") && (
            <Grid item>
              <Field
                component={TextField}
                data-id="RestAPIKey"
                type={
                  RestAPIKeyToggle.passwordIsMasked === true ? "text" : "password"
                }
                variant="outlined"
                label={
                  gatewayDropdownValue.creditCardGatewayID === "Windcave" ? "API Key" : "Validation Code"
                }
                className={classes.formControl}
                name="RestAPIKey"
                role="RestAPIKey"
                InputProps={{
                  endAdornment: (
                    <RemoveRedEye
                      className={classes.eye}
                      onClick={toggleRestAPIKey}
                    />
                  ),
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  disabled: Boolean(disabled),
                }}
                disabled={disabled}
              />
              <SettingsResetButton
                entityID={facilityID}
                entityType={entityType}
                settingName="RestAPIKey"
                currentValue={values.RestAPIKey}
                originalValue={creditCardGatewaySettingParent.value.RestAPIKey}
                onClick={() => {
                  setFieldValue(
                    "RestAPIKey",
                    creditCardGatewaySettingParent.value.RestAPIKey
                  );
                }}
              />
            </Grid>
          )}
          {(canRenderField("FlexAPIUser") && canRenderField("FlexAPIKey")) && eCommerceSplitApi && !isChildFacility && (
            <Grid item xs={12}>
                <h2>{gatewayDropdownValue.creditCardGatewayID === "Windcave" ? "Flexible Parking eCommerce Credentials": "Web2Pay Credentials"}</h2>
            </Grid>
          )}
          {canRenderField("FlexAPIUser") && eCommerceSplitApi && !isChildFacility && (
            <Grid item>
              <Field
                component={TextField}
                data-id="FlexAPIUser"
                variant="outlined"
                label={
                  gatewayDropdownValue.creditCardGatewayID === "Windcave" ? "API User" : "eMerchant ID"
                }
                className={classes.formControl}
                name="FlexAPIUser"
                role="FlexAPIUser"
                disabled={disabled}
                InputProps={{
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  disabled: Boolean(disabled),
                  "data-testid": "FlexAPIUser"
                }}
              />
            </Grid>
          )}
          {canRenderField("FlexAPIKey") && eCommerceSplitApi && !isChildFacility && (
            <Grid item>
              <Field
                component={TextField}
                data-id="FlexAPIKey"
                type={
                  FlexAPIKeyToggle.passwordIsMasked === true ? "text" : "password"
                }
                variant="outlined"
                label={
                  gatewayDropdownValue.creditCardGatewayID === "Windcave" ? "API Key" : "Validation Code"
                }
                className={classes.formControl}
                name="FlexAPIKey"
                role="FlexAPIKey"
                InputProps={{
                  endAdornment: (
                    <RemoveRedEye
                      className={classes.eye}
                      onClick={toggleFlexAPIKey}
                    />
                  ),
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  disabled: Boolean(disabled),
                }}
                disabled={disabled}
              />
            </Grid>
          )}
          {(canRenderField("HITUser") && 
            canRenderField("HITKey")) && !isChildFacility && (
            <Grid item xs={12}>
              <h2>HIT API Information</h2>
            </Grid>
          )}
          {canRenderField("HITUser") && !isChildFacility && (
            <Grid item>
              <Field
                component={TextField}
                data-id="hituser"
                data-hit-user="HITUser"
                variant="outlined"
                label="HIT User"
                className={classes.formControl}
                name="HITUser"
                aria-label="hituser"
                disabled={disabled}
                role="HITUser"
                inputProps={{
                  "aria-label": "hituser"
                }}
                InputProps={{
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  "aria-label": "hituser",
                  disabled: Boolean(disabled),
                }}
              />
            </Grid>
          )}
          {canRenderField("HITKey") && !isChildFacility && (
            <Grid item>
              <Field
                component={TextField}
                data-id="hitkey"
                data-hit-key="HITKey"
                type={
                  hitAPIKeyToggle === true ? "text" : "password"
                }
                variant="outlined"
                label="HIT Key"
                className={classes.formControl}
                name="HITKey"
                role="HITKey"
                aria-label="hitkey"
                inputProps={{
                  "aria-label": "hitkey",
                  "data-testid": "hitkey"
                }}
                InputProps={{
                  endAdornment: (
                    <RemoveRedEye
                      className={classes.eye}
                      onClick={toggleHITAPIKey}
                    />
                  ),
                  readOnly: Boolean(disabled),
                  "aria-readonly": Boolean(disabled),
                  "aria-label": "hitkey",
                  disabled: Boolean(disabled)
                }}
                disabled={disabled}
              />
            </Grid>
          )}
          <Grid item style={{ margin: "10px 0" }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              data-id="GatewaySettingsSubmit"
              onClick={submitForm}
              disabled={disabled}
              name="GatewaySettingsSubmit"
            >
              Save Settings
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type="button"
              variant="contained"
              data-id="GatewaySettingsClose"
              onClick={onClose}
            >
              Close
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

GatewaySettings.defaultProps = {
  disabled: false,
  onClose: () => { },
};

GatewaySettings.propTypes = {
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  entityType: PropTypes.oneOf(["Facility", "facility", "Device", "device", "ValetArea", "valetarea"])
    .isRequired,
};

export default React.memo(GatewaySettings);
