import PropTypes from "prop-types";

export const GATEWAY_TYPES = {
  WINDCAVE: 1,
  PLANET: 2,
};

export default function useGatewayFormUtilities(entityType, gatewayType) {
  const canRenderWindcaveField = (fieldName) => {
    if (!fieldName) return false;
    if (entityType?.toLowerCase() === "facility") {
      switch (fieldName.toLowerCase()) {
        case "currency":
        case "timeout":
        case "restapiuser":
        case "restapikey":
        case "flexapiuser":
        case "flexapikey":
        case "hituser":
        case "hitkey":
          return true;
        default:
          return false;
      }
    } else if (entityType?.toLowerCase() === "facilitygroup") {
      switch (fieldName.toLowerCase()) {
        case "currency":
        case "timeout":
        case "restapiuser":
        case "restapikey":
        case "flexapiuser":
        case "flexapikey":
        case "hituser":
        case "hitkey":
          return true;
        default:
          return false;
      }
    }  else if (entityType?.toLowerCase() === "valetarea") {
      switch (fieldName.toLowerCase()) {
        case "currency":
        case "timeout":
        case "restapiuser":
        case "restapikey":
        case "flexapiuser":
        case "flexapikey":
        case "hituser":
        case "hitkey":
          return true;
        default:
          return false;
      }
    } 
  };

  const canRenderPlanetField = (fieldName) => {
    if (!fieldName) return false;
    if (entityType?.toLowerCase() === "facility") {
      switch (fieldName.toLowerCase()) {
        case "currency":
        case "timeout":
        case "restapiuser":
        case "restapikey":
        case "requesterlocationid":
          return true;
        default:
          return false;
      }
    } else if (entityType?.toLowerCase() === "facilitygroup") {
      switch (fieldName.toLowerCase()) {
        case "currency":
        case "timeout":
        case "restapiuser":
        case "restapikey":
        case "requesterlocationid":
          return true;
        default:
          return false;
      }
    }  else if (entityType?.toLowerCase() === "valetarea") {
      switch (fieldName.toLowerCase()) {
        case "currency":
        case "timeout":
        case "restapiuser":
        case "restapikey":
        case "requesterlocationid":
          return true;
        default:
          return false;
      }
    } 
  };

  const canRenderField = (fieldName) => {
    switch (gatewayType) {
      case GATEWAY_TYPES.WINDCAVE:
        return canRenderWindcaveField(fieldName);
      case GATEWAY_TYPES.PLANET:
        return canRenderPlanetField(fieldName);
    }
  };

  return { canRenderField };
}

useGatewayFormUtilities.propTypes = {
  entityType: PropTypes.oneOf(["facility", "device"]).isRequired,
  gatewayType: PropTypes.number.isRequired,
};
