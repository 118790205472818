import { purple, yellow } from "@material-ui/core/colors";
import { makeStyles, lighten, darken} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "!important none",
    fontSize: "medium"
  },
  filterIcon: {
    padding: "5px",
    float: "right",
  },
  tableCell: {
    fontSize: "1.2em",
    borderBottom: "none",
    display: "flex",
    alignItems: "center",
    justifySelf: "center",
    alignSelf: "center"
  },
  filterIcon: {
    padding: "5px",
    float: "right",
  },
  activityDate: {
    color:darken(theme.palette.primary.dark, .2),
    '&.entry': {
      position:"relative",
      color:darken(theme.palette.success.dark, .2),
    },
    '&.exit': {
      position:"relative",
      color:darken(theme.palette.primary.dark, .2),
    },
    '&.exit::before': {
      content: "'Exit'",
      textAlign:"center",
      fontSize:9,
      position:"absolute",
      top:2, left:-30,
      width:25,
      border:"1px solid silver",

    },
    '&.entry::before': {
      content: "'Entry'",
      textAlign:"center",
      fontSize:9,
      position:"absolute",
      top:2, left:-30,
      width:25,
      border:"1px solid silver",

    }    
  },
  activityDateIcon: {
    marginRight:4,
    '&.entry': {
      color:theme.palette.success.light,
    },
    '&.exit': {
      color:theme.palette.primary.light,
    },
  },  
  drawerPaper: {
    width: "50%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  pointer: {
    cursor: "pointer",
  },
  resultText: {
    background: theme.palette.secondary.main,
    color: "#fff"
  },
  activityAccordion: {
    overflowX: "auto"
  },
  editButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      marginLeft: theme.spacing(1)
    }
  },
  removeButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      marginLeft: theme.spacing(2)
    }
  },
  clickableRow: {
    cursor: "pointer",
    margin: "5px",
    "&:hover": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor:lighten(theme.palette.primary.light,.8),
    },
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },
}));
