import React from "react";
import PropTypes from "prop-types";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import { useStyles } from "./styles";

const getAvailableOptions = (type) => {
  switch (type) {
    case "sealevel":
      return getSeaLevelOptions();
    case "ami":
      return getAmiOptions();
    case "directGPIO":
      return getDirectGpioOptions();
    default:
      return null;
  }
};

const getLabel = (type) => {
  switch (type) {
    case "sealevel":
    case "ami":
      return "I/O Point";
    default:
      return "Pin";
  }
};

const PinSelect = ({ field, boardType, className, error }) => {
  const classes = useStyles();
  let options = getAvailableOptions(boardType);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="pin-select-label">{getLabel(boardType)}</InputLabel>
      <Select
        {...field}
        labelId="pin-select-label"
        fullWidth
        label={getLabel(boardType)}
        className={className}
        error={!isEmpty(error)}
      >
        {options}
      </Select>
      <FormHelperText className={classes.errorText}>{error}</FormHelperText>
    </FormControl>
  );
};

PinSelect.propTypes = {
  boardType: PropTypes.oneOf(["sealevel", "ami", "directGPIO"]),
  onChange: PropTypes.func,
  error: PropTypes.string,
};

PinSelect.defaultProps = {
  onChange: () => {},
};

export default PinSelect;

const getSeaLevelOptions = () => {
  let options = [];
  for (let i = 1; i <= 4; i++) {
    options.push(
      <MenuItem key={i} value={i - 1}>
        {i}
      </MenuItem>
    );
  }
  return options;
};

const getAmiOptions = () => {
  let options = [];
  for (let i = 1; i <= 6; i++) {
    options.push(
      <MenuItem key={i} value={i - 1}>
        {i}
      </MenuItem>
    );
  }
  return options;
};

const getDirectGpioOptions = () => {
  let options = [];
  for (let i = 1; i <= 40; i++) {
    options.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }
  return options;
};
