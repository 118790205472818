import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import UserService from "../../../services/UserService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { List } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./style";
import * as _ from "lodash";
import UserListItem from "./UserListItem";
import useUserSelect from "./useUserSelect";

const userService = new UserService(apiClient);

export default function UserList({
  facilityID,
  limit,
  searchTerm,
  selected,
  disabled,
  onChange,
}) {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [users, setUsers] = useState([]);
  const { onCheck, isChecked, selectedUsers } = useUserSelect({ selected });

  useEffect(() => {
    getUsers(facilityID, limit, searchTerm);
  }, [facilityID, limit, searchTerm]);

  useEffect(() => {
    onChange(selectedUsers);
  }, [selectedUsers]);

  const getUsers = useCallback(
    async (facilityID, limit, searchTerm) => {
      try {
        const response = await userService.getUsers(
          facilityID,
          limit,
          0,
          searchTerm
        );
        setUsers(response.data.collection);
      } catch {
        enqueueSnackbar("Failed to retrieve users", { variant: "error", tag: "FailedToRetrieveUsers"});
      }
    },
    [facilityID, limit, searchTerm]
  );

  return (
    <List className={clsx(classes.userList)}>
      {users.map((user, index) => {
        let checked = isChecked(user.userId);
        return (
          <UserListItem
            key={index}
            user={user}
            checked={checked}
            disabled={disabled}
            onClick={onCheck}
          />
        );
      })}
    </List>
  );
}

UserList.defaultProps = {
  limit: 50,
  searchTerm: "",
  selected: [],
  disabled: false,
  onChange: () => {},
};

UserList.propTypes = {
  facilityID: PropTypes.string.isRequired,
  limit: PropTypes.number,
  searchTerm: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
