import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  guesttypeLabel: {
    fontSize: '1rem',
    color: theme.palette.primary.dark,
  },
  guesttypeDivider: {
    marginBottom: '15px'
  },
  guesttypeGrid: {
    width: "100%",
    margin: theme.spacing(1)
  },
  guesttypeBox: {
    padding: '10px',
    height: "calc(100vh - 100px)",
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  guesttypeLine: {
    marginTop: theme.spacing(1),
  },
  guesttypeDetail: {
    fontSize: '0.6rem',
    whiteSpace: "nowrap",
    width: '90%',
    overflow: 'hidden',
  },
  guesttypesDrawer: {
    display: "inline-block",
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  cancelBtn: {
    margin: theme.spacing(2),
    float: "right",
    right: '10px',
  },
  guesttypeDividerFixed: {
    marginBottom: '10px',
    marginRight: '20px',
    marginLeft: '20px',
  },
  drawerDetailConfiguration: {
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  headerContainerSpacing: {
    marginTop: '15px'
  },
  addButton: {
    float: "right",
  },
}));