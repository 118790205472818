import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Card,
  CardContent,
} from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";

const ItemList = ({
  items,
  checked,
  selectionChanged,
  onRowClicked,
  hideCheckBox,
  disableCheckBoxForID,
  disableSelect,
}) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {items.map(({ id, name, displayContent, dataId }, index) => {
        return (
          <ListItem key={index} className={classes.listItem}>
            {hideCheckBox === false && (
              <ListItemIcon
                className={classes.listIcon}
                onClick={() => selectionChanged(id)}
              >
                <Checkbox
                  className={clsx('checkbox')}
                  data-id={dataId === undefined ? id : dataId}
                  defaultChecked={checked.includes(id)}
                  edge="start"
                  tabIndex={-1}
                  color="primary"
                  disabled={id === disableCheckBoxForID}
                />
              </ListItemIcon>
            )}
            <Card
              variant="outlined"
              data-id={dataId === undefined ? (name === undefined ? id : name) : dataId}
              className={clsx(
                classes.card,
                disableSelect ? classes.unselectable : ""
              )}
              onClick={() => onRowClicked(id)}
            >
              <CardContent className={classes.cardContent}>
                {displayContent}
              </CardContent>
            </Card>
          </ListItem>
        );
      })}
    </List>
  );
};

ItemList.defaultProps = {
  items: [],
  selectionChanged: () => {},
  onRowClicked: () => {},
  hideCheckBox: false,
  hideCheckBoxForID: "",
  checked: [],
  disableSelect: false,
};

ItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayContent: PropTypes.node,
    })
  ),
  selectionChanged: PropTypes.func,
  onRowClicked: PropTypes.func,
  hideCheckBox: PropTypes.bool,
  hideCheckBoxForID: PropTypes.string,
  checked: PropTypes.array,
  disableSelect: PropTypes.bool,
};

export default ItemList;
