import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useStyles } from "./styles";
import { isUndefined, isNull } from "lodash";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";

export const ImportHistoryTable = ({ rows }) => {
  const classes = useStyles();
  const { convertUtcDateToFormat } = useCurrentFacilityTimezone();

  function formatDate(dateStr) {
    if (isUndefined(dateStr) || isNull(dateStr)) {
      return "";
    }
    return convertUtcDateToFormat(dateStr);
  }

  const parseStatus = statusStr => {
    const status = statusStr.toLowerCase();
    let returnedStatus;
    switch (status) {
      case "notprocessed":
        returnedStatus = "Not Processed";
        break;
      case "inprogress":
        returnedStatus = "In Progress";
        break;
      case "completedsuccess":
        returnedStatus = "Completed Success";
        break;
      case "completederror":
        returnedStatus = "Completed Error";
        break;
      default:
        break;
    }
    return returnedStatus;
  };
  return (
    <Table style={{ whiteSpace: "pre-wrap" }}>
      <TableHead>
        <TableRow>
          <TableCell align="center" className={classes.column}>
            Date
          </TableCell>
          <TableCell align="center" className={classes.column}>
            Status
          </TableCell>
          <TableCell align="center" className={classes.column}>
            Info
          </TableCell>
          <TableCell align="center" className={classes.column}>
            Uploaded By
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            <TableCell align="center">
              {row.uploadDateTime ? formatDate(row.uploadDateTime) : ""}
            </TableCell>
            <TableCell align="center">
              {row.status ? parseStatus(row.status) : ""}
            </TableCell>
            <TableCell align="center">{row.message ?? ""}</TableCell>
            <TableCell align="center">{row.uploadBy ?? ""}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ImportHistoryTable;
