import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";

/* Style */
import { useStyles } from "./styles";
import clsx from "clsx";

const SortableList = ({ items, onSort, className, identifier }) => {
  const classes = useStyles();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const oldSort = [...items];
    const newSort = reorder(items, source.index, destination.index);
    onSort(newSort, oldSort);
  };

  return (
    <div data-testid={identifier} className={clsx(className, classes.root)}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items &&
                items.map((item, index) => (
                  <DraggableListItem item={item} index={index} key={item.id} />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

SortableList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
  onSort: PropTypes.func.isRequired,
};

const useListItemStyles = makeStyles({
  draggingListItem: {
    background: "rgb(235,235,235)",
  },
});

const DraggableListItem = ({ item, index }) => {
  const classes = useListItemStyles();
  return (
    <Draggable
      draggableId={item.id.toString()}
      index={index}
      isDragDisabled={false}
    >
      {(provided, snapshot) => (
        <ListItem
          data-testid={`draggable-${item.id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ""}
        >
          <ListItemAvatar>
            <Avatar>{index + 1}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.value} />
          <DragHandleIcon />
        </ListItem>
      )}
    </Draggable>
  );
};
export default SortableList;
