import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
  },
  title: {
    display: "inline-flex",
    justifyContent: "space-between",
    margin: theme.spacing(2),
  },
  previewChip: {
    fullWidth: true,
  },
  dropboxContainer: {
    marginLeft: "25%",
    marginRight: "25%",
    width: "100%",
  },
  getHistoryBtn: {
    marginLeft: "16px",
  },
  submitBtn: {
    float: "right",
    top: 0,
    margin: theme.spacing(1),
  },
  gridRow: {
    width: "100%",
  },
  errorBox: {
    [theme.breakpoints.down("lg")]: {
      marginLeft: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "0%",
    },
    marginRight: "20%",
    marginTop: theme.spacing(1),
    width: "80%",
    textAlign: "justify",
  },
  tableContainer: {
    width: "100%",
    margin: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
  icon: {
    color: "primary",
  },
  error: {
    color: "red",
  },
}));
