import React from "react";
import { useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Divider,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  InputLabel,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { GPIO_BOARD_TYPES, GPIO_TYPE, GPIO_COUNT_TYPE } from "../../constants/index";
import PinSelect from "./PinSelect";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";

export const addEditSchema = Yup.object().shape({
  gpioName: Yup.string().required("Required"),
  gpioPortNumber: Yup.number()
    .moreThan(-1)
    .lessThan(41)
    .required("Required"),
  countDetails: Yup.array(
    Yup.object({
      increment: Yup.string().required("Required"),
      countType: Yup.string().required("Required")
    })
  )
});

const GPIOSideDrawer = ({
  ioMap,
  handleSave,
  handleCancel,
  deviceID,
  systemEvents,
  isUsedForRateTrigger
}) => {
  const classes = useStyles();
  const { showDialog } = useConfirmationDialog();

  const mathIncrementOptions = [
    { label: "Add", value: true },
    { label: "Subtract", value: false }
  ]

  const updateMapping = () => {
    reset({
      ...ioMap,
      gpioName: ioMap.friendlyName,
      boardType: getBoardType(ioMap.boardType),
      gpioPortNumber: ioMap.gpioPortNumber,
      gpioIsNormallyOpen: ioMap.gpioIsNormallyOpen == true ? "true" : "false",
      gpioType: ioMap.gpioType,
      systemEvents: ioMap.systemEventID ?? 0,
      countDetails: ioMap.countDetails
    });
    replace(ioMap.countDetails ?? {})
  };

  useEffect(() => {
    if (Object.keys(ioMap).length > 0) updateMapping();
  }, [ioMap]);

  let defaults = {
    gpioName: "",
    boardType: 1,
    gpioPortNumber: 1,
    gpioIsNormallyOpen: "false",
    gpioType: "Input",
    systemEvents: 0,
    countDetails: []
  };

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    defaultValues: defaults,
    resolver: yupResolver(addEditSchema),
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "countDetails"
  });
  const addNewOccupancyAdjustment = () => {
    append([{ increment: "", countType: "" }])
  }
  const deleteOccupancyAdjustment = async (index) => {
    const response = await showDialog({
      title: `Are you sure you want to delete this occupancy adjustment?`,
      message: `This will delete the selected occupancy adjustment.`,
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" },
      ],
    });
    if (response !== "Yes") return;
    remove(index);
  }

  const selectedBoardType = watch("boardType");
  const gpioType = watch("gpioType");

  function getBoardType(key) {
    var result = GPIO_BOARD_TYPES[key];
    return result;
  }

  function GetGPIOType(key) {
    if (key === undefined) return true;
    var result = GPIO_TYPE[key];
    if (result === 0 || result === 2) {
      return true;
    }
    return false;
  }

  const FormatAndSave = (values) => {
    if (values?.systemEvents === undefined || values.systemEvents === 0)
      values.systemEvents = null;

    var mapToSave = {
      ioMappingID: values?.ioMappingID,
      deviceID: deviceID,
      gpioIsNormallyOpen:
        values?.gpioIsNormallyOpen === undefined
          ? false
          : (values.gpioIsNormallyOpen = values?.gpioIsNormallyOpen === "true"),
      gpioPortNumber: values?.gpioPortNumber,
      gpioType:
        values?.gpioType === undefined
          ? 2
          : values?.gpioType === true || values.gpioType === "Input"
            ? GPIO_TYPE["Input"]
            : GPIO_TYPE["Output"],
      gpioName: values?.gpioName,
      boardType: values?.boardType === undefined ? 0 : values?.boardType,
      systemEventID: values.systemEvents,
      friendlyName: values?.gpioName,
      countDetails: values?.gpioType === "Input"
        ? values?.countDetails
        : []
    };

    handleSave(mapToSave);
  };

  const getBoardTypeForPinSelect = (type) => {
    switch (type) {
      case GPIO_BOARD_TYPES.Sealevel:
        return "sealevel";
      case GPIO_BOARD_TYPES.AMI:
        return "ami";
      case GPIO_BOARD_TYPES["Direct GPIO"]:
        return "directGPIO";
      default:
        return "";
    }
  };

  const arrayOfItemsToDisable = useWatch({
    control: control,
    name: "countDetails"
  });
  const selectedItems = arrayOfItemsToDisable?.map((item) => item["countType"]);


  return (
    <Grid container>
      <Grid item sm={12}>
        <Typography
          variant="h5"
          color="primary"
          component="h2"
          className={clsx("title", classes.DrawerTitle)}
        >
          Add / Edit
        </Typography>

        <form
          className={classes.form}
          onSubmit={handleSubmit((values) => FormatAndSave(values))}
          noValidate
        >
          <Grid container className={classes.drawerContainer}>
            <Grid item xs={12}>
              <Controller
                name="gpioName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={clsx("name-field", classes.newPassword)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Name"
                    id="gpioName"
                    error={!!errors.gpioName}
                    helpertext={errors.gpioName && errors.gpioName.message}
                  />
                )}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Controller
                  name="boardType"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      formlabel="Board Type"
                      variant="outlined"
                    >
                      <InputLabel
                        id="boardLabel"
                        className={clsx("dropdown-label")}
                      >
                        Board Type
                      </InputLabel>
                      <Select
                        {...field}
                        displayEmpty
                        className={clsx("board-dropdown")}
                        variant="outlined"
                        fullWidth
                        labelId="boardLabel"
                        label="Board Type"
                        aria-label="Board Type"
                        inputProps={{
                          id: "board-dropdown",
                        }}
                        error={!!errors.boardType}
                        helpertext={
                          errors.boardType && errors.boardType.message
                        }
                      >
                        {Object.keys(GPIO_BOARD_TYPES)?.map((item, index) => {
                          if (item != "Unset") {
                            return (
                              <MenuItem
                                key={item}
                                value={index}
                                className={clsx(`${item}-menu-item`)}
                                name="boardType"
                              >
                                {item}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="gpioType"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      formlabel="Input / Output"
                      variant="outlined"
                    >
                      <Tooltip title={isUsedForRateTrigger ? "This input is currently being used as trigger for secondary rate." : ""}>
                        <span>
                          <InputLabel
                            id="typeLabel"
                            className={clsx("dropdown-label")}
                          >
                            Input / Output
                          </InputLabel>
                          <Select
                            {...field}
                            displayEmpty
                            className={clsx("type-dropdown")}
                            variant="outlined"
                            fullWidth
                            labelId="typeLabel"
                            label="Input / Output"
                            aria-label="Input / Output"
                            value={field.value}
                            inputProps={{
                              id: "type-dropdown",
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger();
                            }}
                            disabled={isUsedForRateTrigger}
                            error={!!errors.gpioType}
                            helpertext={errors.gpioType && errors.gpioType?.message}
                          >
                            {Object.keys(GPIO_TYPE)?.map((item, index) => {
                              if (item != "Unset") {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={item}
                                    className={clsx(`${item}-menu-item`)}
                                    name="gpioType"
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </span>
                      </Tooltip>
                    </FormControl>
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="gpioPortNumber"
                  control={control}
                  render={({ field }) => (
                    <PinSelect
                      field={field}
                      boardType={getBoardTypeForPinSelect(selectedBoardType)}
                      className={clsx("PortNumber", classes.pinField)}
                      error={
                        errors.gpioPortNumber && errors.gpioPortNumber.message
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className={classes.radioControls}>
                <Controller
                  name="systemEvents"
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="systemEvent">System Event</InputLabel>
                      <Select
                        {...field}
                        displayEmpty
                        className={clsx("system-event-dropdown")}
                        labelId="systemEvent"
                        label="System Event"
                        aria-label="System Event"
                        inputProps={{
                          id: "systemEvents-dropdown",
                        }}
                      >
                        {systemEvents.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={item.systemEventID}
                              name="boardType"
                              className={clsx([
                                `${item.eventName}`,
                                "menu-item",
                              ])}
                            >
                              {item.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              {GetGPIOType(gpioType) && (
                <Grid container className={classes.container}>
                  <Grid item xs={6} className={classes.radioControls}>
                    <Controller
                      name="gpioIsNormallyOpen"
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormLabel component="legend">
                            Signal is active when:
                          </FormLabel>
                          <RadioGroup id="name" {...field}>
                            <FormControlLabel
                              className={clsx("open-radio-button")}
                              value="true"
                              control={<Radio color="primary" />}
                              label="Low"
                            />
                            <FormControlLabel
                              className={clsx("closed-radio-button")}
                              value="false"
                              control={<Radio color="primary" />}
                              label="High"
                            />
                          </RadioGroup>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {GetGPIOType(gpioType) && (
            <Grid>
              <Grid container className={classes.drawerContainer}>
                <Typography className={clsx([classes.occupancyTitle])}>
                  Occupancy Adjustment
                </Typography>
              </Grid>
              {fields.map((field, index) => (
                <Grid
                  container
                  spacing={1}
                  key={field.id}
                  className={classes.occupancyContainer}
                >
                  <Grid item xs={4}>
                    <Controller
                      name={`countDetails.${index}.increment`}
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          formlabel="Math"
                          variant="outlined"
                        >
                          <InputLabel
                            id="mathLabel"
                            className={clsx("dropdown-label")}
                          >
                            Math
                          </InputLabel>
                          <Select
                            {...field}
                            className={clsx(classes.occupancySelect)}
                            displayEmpty
                            variant="outlined"
                            fullWidth
                            labelId="mathLabel"
                            label="Math"
                            aria-label="Math"
                            data-testid={`countDetails.${index}.increment-select`}
                            error={errors.countDetails && errors.countDetails?.[index]?.increment?.message}
                          >
                            {mathIncrementOptions.map((item, index) => (
                              <MenuItem
                                key={item.value + "select-" + index}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.countDetails && (
                            <div className={clsx([classes.errorText])}>
                              {errors.countDetails?.[index]?.increment?.message}
                            </div>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={`countDetails.${index}.countType`}
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          formlabel="Count Type"
                          variant="outlined"
                        >
                          <InputLabel
                            id="countLabel"
                            className={clsx("dropdown-label")}
                          >
                            Count Type
                          </InputLabel>
                          <Select
                            {...field}
                            className={clsx(classes.occupancySelect)}
                            displayEmpty
                            variant="outlined"
                            fullWidth
                            labelId="countLabel"
                            label="Count Type"
                            aria-label="Count Type"
                            data-testid={`countDetails.${index}.countType-select`}
                            error={errors.countDetails && errors.countDetails?.[index]?.countType?.message}
                          >
                            {Object.keys(GPIO_COUNT_TYPE)?.map((item, index) => {
                              if (item != "Unset") {
                                if (selectedItems &&
                                  selectedItems?.length > 0 &&
                                  selectedItems?.includes(item) &&
                                  item != field.value) {
                                  return (
                                    <Tooltip
                                      placement="bottom-end"
                                      title={"This type has already been added."}
                                      key={item + "select-" + index}
                                      value={item}
                                    >
                                      <div onClick={(event) => { event.preventDefault(); }}>
                                        <MenuItem
                                          disabled={true}
                                          key={item + "select-" + index}
                                          value={item}
                                        >
                                          {item}
                                        </MenuItem>
                                      </div>
                                    </Tooltip>
                                  )
                                } else {
                                  return (
                                    <MenuItem
                                      key={item + "select-" + index}
                                      value={item}
                                    >
                                      {item}
                                    </MenuItem>
                                  )
                                }
                              }
                            })}
                          </Select>
                          {errors.countDetails && (
                            <div className={clsx([classes.errorText])}>
                              {errors.countDetails?.[index]?.countType?.message}
                            </div>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} >
                    <IconButton
                      data-testid={`countDetails.${index}-delete-button`}
                      className={clsx(classes.btnControl)}
                      color="secondary"
                      variant="outlined"
                      onClick={() => deleteOccupancyAdjustment(index)}
                      name="delete"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid >
                <Button
                  className={clsx(classes.addButton)}
                  data-id="add-btn"
                  data-testid="add-btn"
                  variant="contained"
                  color="primary"
                  onClick={addNewOccupancyAdjustment}
                  name="add"
                  disabled={fields.length > 2}
                >
                  + Add
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{ marginTop: 20 }}
            className={classes.buttonContainer}
          >
            <Divider />
            <Button
              style={{ float: "right" }}
              startIcon={<CancelIcon />}
              className={clsx("cancelBtn", classes.btnControl)}
              data-id="cancelBtn"
              name="cancel"
              variant="contained"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              startIcon={<SaveIcon />}
              color="primary"
              data-id="saveBtn"
              name="Save"
              type="submit"
              variant="contained"
              className={clsx("saveBtn", classes.btnControl)}
            >
              Save
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default GPIOSideDrawer;
