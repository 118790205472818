
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  accessHolderName: {
    fontWeight: "bold",
    fontSize: "large",
    justifyContent: "left",
    alignItems: "left",
    flex: "1",
    flexDirection: "row",
    textAlign: "left",
    textAlignVertical: "center",
    alignSelf: "center"
  },
  displayField: {
    display: "inline-flex",
    spacing: theme.spacing(2)
  }
}));
