import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: "calc(5em * 5)",
    maxWidth: "100%",
    marginBottom: "5%"
  },
  HelperControl: {
    minWidth: "250px",
    maxWidth: "100%",
    marginTop: "0%",
    marginBottom: "5%",
    paddingTop: "0%",
    paddingLeft: "5%"
  },
  eye: {
    cursor: 'pointer',
  },
}));