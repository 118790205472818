import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    width: "85%"
  },
  flexChild: { margin: theme.spacing(1), flexGrow: "1" },
  ahFilters: {
    marginLeft: theme.spacing(20)
  },
  addAhButton: {
    marginLeft: theme.spacing(2)
  },
  contractAccessHolders: {
    width: "100%",
    display: "flex", 
    margin: theme.spacing(1),
    marginLeft: theme.spacing(0)
  },
  accessHolders: {
    marginLeft: theme.spacing(1),
    width: "100%"
  },
  paper: {
    width: "120%"
  },
  credentials: { flexBasis: "100%" },
  contracts: { flexBasis: "100%" },
  modal: {
    minWidth: "1000px",
    [theme.breakpoints.down("md")]: {
      minWidth: "600px"
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "200px"
    }
  }
}));
