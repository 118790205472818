import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  Checkbox,
  Avatar,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./style";
import * as _ from "lodash";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

export default function UserListItem({
  user,
  checked,
  disabled,
  onClick,
  options,
  editClick,
  deleteClick,
  handleResend,
  isResetting,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEditClick = () => {
    editClick(user);
  };

  const handleDeleteClick = () => {
    deleteClick(user);
  };

  const handleResendClick = () => {
    // if handleResend was passed as prop, call it
    if (typeof handleResend == "function") handleResend(user);
  };

  return (
    <ListItem
      className={clsx("user")}
      data-firstname={`${user.firstName}`}
      data-lastname={`${user.lastName}`}
      data-email={`${user.email}`}
      data-checked={checked}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
          className={clsx("user-info", classes.userInfo)}
        >
          <Grid container>
            <Grid
              item
              xs={3}
              className={clsx("checkbox-container", classes.smallContainer)}
            >
              <Checkbox
                className={clsx(`user-checkbox`)}
                color="primary"
                onChange={(event) =>
                  onClick(user.userId, event.target.checked, event)
                }
                value={user.userId}
                checked={checked}
                disabled={disabled}
              />
            </Grid>
            <Grid
              item
              xs={3}
              className={clsx("avatar-container", classes.smallContainer)}
            >
              <Avatar
                className={clsx(
                  `user-${user.firstName} ${user.lastName}-avatar`,
                  classes.userAvatar
                )}
                aria-label="user"
                title={user.userId}
              >
                {user.firstName?.charAt(0).toUpperCase()}
              </Avatar>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <Typography
                  className={clsx(
                    `user-${user.firstName} ${user.lastName}-name`,
                    classes.userName
                  )}
                >
                  {`${user.firstName ?? ""} ${user.lastName ?? ""}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={clsx(
                    `user-${user.firstName} ${user.lastName}-email`,
                    classes.userEmail
                  )}
                >
                  {user.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className={clsx("option-group", classes.optionGroup)}
        >
          <div className={clsx("button-group", classes.btnGroup)}>
            {_.includes(options, "edit") && (
              <Button
                className={clsx("edit-button", classes.editBtn)}
                color="primary"
                variant="contained"
                onClick={handleEditClick}
                startIcon={
                  small ? <EditIcon style={{ marginLeft: "33%" }} /> : <></>
                }
              >
                {!small && "Edit"}
              </Button>
            )}
            {_.includes(options, "delete") && (
              <Button
                className={clsx("delete-button", classes.deleteBtn)}
                color="secondary"
                variant="contained"
                onClick={handleDeleteClick}
                startIcon={
                  small ? <DeleteIcon style={{ marginLeft: "33%" }} /> : <></>
                }
              >
                {!small && "Delete"}
              </Button>
            )}
            {_.includes(options, "add") && (
              <Button
                className={clsx("btn-resend", classes.resendBtn)}
                startIcon={
                  isResetting ? (
                    <CircularProgress
                      size={24}
                      color="secondary"
                      style={{ marginLeft: "33%" }}
                    />
                  ) : (
                    <RotateLeftIcon style={{ marginLeft: "33%" }} />
                  )
                }
                variant="contained"
                disabled={isResetting}
                onClick={handleResendClick}
              >
                {!small && "Resend Invitation"}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
}

UserListItem.defaultProps = {
  user: {},
  checked: false,
  disabled: false,
  onClick: () => {},
  options: [],
  editClick: () => {},
  deleteClick: () => {},
};

UserListItem.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOf(["edit", "delete", "add"])),
  editClick: PropTypes.func,
  deleteClick: PropTypes.func,
};
