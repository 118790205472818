import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
  },
  listItem: {
    width: "inherit",
  },
  chip: {
    cursor: "pointer",
    margin: "0 1%",
  },
  card: {
    width: "100%",
    cursor: "pointer",
  },
  cardContent: {
    width: "100%",
  },
  listIcon: {
    minWidth: "50px",
  },
  chipGrid: {
    lineHeight: "50px",
  },
  icon: {
    cursor: "pointer",
  },
  filterBox: {
    marginBottom: theme.spacing(2),
  },
  filterChip: {
    cursor: "pointer",
    margin: "0 1%",
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  mobileAdd: {
    "& .MuiButton-startIcon": {
      margin: 0,
    },
  },
  unselectable: {
    pointerEvents: "none",
    "&:hover": {
      cursor: "pointer !important",
    },
  },
}));
