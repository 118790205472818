import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  userRow: {
    paddingLeft: "0px",
    display: "flex",
  },
  userAvatar: {
    alignSelf: "center",
  },
  userCheckbox: {
    float: "right",
    maxWidth: "50px",
  },
  smallContainer: {
    maxWidth: "50px",
  },
  userInfo: {
    display: "flex",
    float: "left",
  },
  userName: {
    fontWeight: "bold",
  },
  userEmail: {},
  optionGroup: {
    width: "100%",
  },
  btnGroup: {
    float: "right",
    alignItems: "center",
    justifyContent: "center",
  },
  editBtn: { 
    margin: theme.spacing(1),
  },
  deleteBtn: { 
    margin: theme.spacing(1),
  },
  resendBtn: { 
    margin: theme.spacing(1),
  },
}));
