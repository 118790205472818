import React, { useState, useEffect } from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import Footer from "../Footer";
import SideDrawer from "../SideDrawer";
import newLogo from "../../assets/logos/a1-logo-horizontal-dark-cropped.svg";
import { useStyles } from "./styles";
import Clock from "../Clock";
import useThemeSwitch from "../../hooks/useThemeSwitch";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useHasPermissions from "../../hooks/useHasPermissions";
import PropertyButton from "../PropertyButton";
import QuickActionsButton from "../QuickActionsButton";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb as DarkModeIcon,
  faKey,
  faSignOut,
} from "@fortawesome/pro-duotone-svg-icons";
import { faLightbulb as LightModeIcon } from "@fortawesome/pro-solid-svg-icons";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import NotificationIcon from "../Notifications/NotificationIcon";
import MultiSearchBar from "../Search/MultiSearchBar";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import UserDemographics from "../UserDemographics";
import useUserActions from "../../hooks/useUserActions/useUserActions";
import { useAppActionContext } from "../../providers/AppStore/AppActionProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import ScopeSelection from "../ScopeSelection";
import LayoutErrorBoundary from "../LayoutErrorBoundary";

const Layout = (props) => {
  const { logOut } = useAppActionContext();
  const theme = useTheme();
  const { toggleTheme, isLight, layoutReducer } = useThemeSwitch();
  const [layout] = layoutReducer;
  const classes = useStyles();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(isLargeScreen);
  const history = useHistory();
  const [showDemographics, setShowDemographics] = useState(false);
  const { handleLogOut } = useUserActions();
  const { hasPermissions } = useHasPermissions();
  const notificationsCallCenterPermissions = hasPermissions([
    "callcenter.view",
  ]);
  const { omniSearch } = useFlags();

  const currentUser = useSelector((state) => state.user);
  const UserInits = currentUser.Username?.split(" ")
    .map((n) => n[0])
    .join("");

  const location = useLocation();

  const handleLogOutClick = async () => {
    setAnchorEl(null);
    return handleLogOut();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReset = () => {
    history.push("/login/reset");
  };

  const handleProfile = () => {
    setShowDemographics((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowDemographics(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (location.pathname == "/logout") {
      return logOut();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLargeScreen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isLargeScreen]);

  return (
    <React.Fragment>
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          "amano-top-bar",
          open && classes.appBarShift
        )}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            name="openDrawerButton"
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          {isLargeScreen && !open && (
            <img
              className={classes.newLogoSvg}
              alt="company logo"
              src={newLogo}
            />
          )}

          {omniSearch && <MultiSearchBar />}
          <Box flexGrow={1} />
          <QuickActionsButton />          
          <PropertyButton />
          <ScopeSelection />
          {isLargeScreen && <Clock />}
          {notificationsCallCenterPermissions && (
            <NotificationIcon title="Notifications" alt="Notifications" />
          )}

          <IconButton
            name="themeIcon"
            color="inherit"
            onClick={toggleTheme}
            alt="Toggle Light/Dark Mode"
            title="Toggle Light/Dark Mode"
          >
            {isLight ? (
              <FontAwesomeIcon icon={LightModeIcon} />
            ) : (
              <FontAwesomeIcon icon={DarkModeIcon} />
            )}
          </IconButton>

          <Avatar
            alt="user-initials"
            onClick={handleClick}
            style={{
              color: theme.palette.background.paper,
              backgroundColor: theme.palette.info.main,
              cursor: "pointer",
            }}
            className={clsx(`account-icon`)}
          >
            {UserInits}
          </Avatar>
          <Menu
            className={clsx(["user-menu"])}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={
              showDemographics
                ? {
                    style: {
                      width: 350,
                    },
                  }
                : {}
            }
          >
            <Typography
              style={{ padding: 6, paddingLeft: 14 }}
              className={classes.usersName}
            >
              {currentUser.Username}
            </Typography>
            <Divider />
            <MenuItem
              onClick={handleProfile}
              className={clsx(["menu-item", "profile"])}
            >
              <ListItemIcon style={{ marginRight: -15 }}>
                <FontAwesomeIcon icon={faUserCog} />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            {showDemographics && (
              <div onKeyDown={(e) => e.stopPropagation()}>
                <UserDemographics />
              </div>
            )}
            {!showDemographics && [
              <MenuItem
                key="change-password"
                onClick={handleReset}
                className={clsx(["menu-item", "change-password"])}
              >
                <ListItemIcon style={{ marginRight: -15 }}>
                  <FontAwesomeIcon icon={faKey} />
                </ListItemIcon>
                <ListItemText primary="Change Password" />
              </MenuItem>,
              <MenuItem
                key="logout"
                onClick={handleLogOutClick}
                className={clsx(["menu-item", "logout"])}
              >
                <ListItemIcon style={{ marginRight: -15 }}>
                  <FontAwesomeIcon icon={faSignOut} />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </MenuItem>,
            ]}
          </Menu>
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="js">
        <SideDrawer
          name="mobileDrawer"
          mobile
          isOpen={open}
          onDrawerCloseRequested={handleDrawerClose}
        />
      </Hidden>
      <Hidden xsDown implementation="js">
        <SideDrawer
          name="defaultDrawer"
          isOpen={open}
          onDrawerCloseRequested={handleDrawerClose}
        />
      </Hidden>

      <Box className={classes.mainContent}>
        <main className={classes.mainTag}>
          <div className={classes.appBarSpacer} />
          <LayoutErrorBoundary>
            <Container
              maxWidth={layout.rootWidth}
              className={classes.innerContainer}
            >
              {props.children}
            </Container>
          </LayoutErrorBoundary>
        </main>
        <Footer />
      </Box>
    </React.Fragment>
  );
};

export default Layout;
