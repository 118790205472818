import { useState, useEffect, useRef } from "react";
import * as _ from "lodash";

export default function useUserSelect({ selected }) {
  const [selectedUsers, setSelectedUsers] = useState(selected);

  const selectedRef = useRef(selected);

  useEffect(() => {
    if (!_.isEqual(selectedRef.current, selected)) {
      selectedRef.current = selected;
      setSelectedUsers(selected);
    }
  }, [selected]);

  const isChecked = (userId) => {
    return selectedUsers.includes(userId);
  };

  const handleUserChecked = (userId) => {
    let tmpUsers = [...selectedUsers];
    if (tmpUsers.includes(userId)) {
      // remove
      tmpUsers.splice(tmpUsers.indexOf(userId), 1);
    } else {
      // add
      tmpUsers.push(userId);
    }
    setSelectedUsers(tmpUsers);
  };

  return { isChecked, onCheck: handleUserChecked, selectedUsers };
}
