import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import { Grid, Typography, Divider, Button } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import HotelRatesMappingTable from "./HotelRatesMappingTable";
import HotelRatesSideDrawer from "./HotelRatesSideDrawer";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import _ from "lodash";
import { useSettingDispatchChange } from "../Forms/Settings/index";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import AccessGroupService from "../../services/AccessGroupService";
import apiClient from "../../auth/apiClient";
import { useSelector } from "react-redux";

const accessGroupService = new AccessGroupService(apiClient);

const HotelRatesConfiguration = ({ deviceID, hotelInterfaceSettings }) => {
  const { dispatchSetting } = useSettingDispatchChange();
  
  const hotelInterfaceData = JSON.parse(hotelInterfaceSettings);
  if (!hotelInterfaceData.hasOwnProperty("rates")) {
    hotelInterfaceData.rates = [];
  }
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const classes = useStyles();
  const { textConfirmation } = useConfirmationDialog();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hotelRate, setHotelRate] = useState({});
  const [hotelRates, setHotelRates] = useState([...hotelInterfaceData.rates]);
  const [accessGroups, setAccessGroups] = useState([]);
  
  const handleDrawerClose = () => {
     setDrawerOpen(false);
  };

  const onEditClick = async (rateid) => {
    const editRate = hotelRates.find((x) => x.rateid === rateid);
    setHotelRate({ ...editRate });
    setDrawerOpen(true);
  };

  const onAddClick = () => {
    setHotelRate({});
    setDrawerOpen(true);
  };

  const handleSave = async (rateToSave) => {
    try {
      let rates = [...hotelRates];
      if (rateToSave.rateid === undefined) {
        let maxRate = rates.reduce(
          (max, current) => (current.rateid >= max.rateid ? current : max),
          { rateid: 0 }
        );
        let maxRateId = maxRate.rateid + 1;
        const newRate = { ...rateToSave, rateid: maxRateId };
        rates.push(newRate);
      } else {
        rates[
          rates.findIndex((x) => x.rateid == rateToSave.rateid)
        ] = rateToSave;
      }
      hotelInterfaceData.rates = [...rates];
      await dispatchSetting(
        deviceID,
        "hotelinterface",
        JSON.stringify(hotelInterfaceData)
      );
      setHotelRates([...rates]);
      handleDrawerClose();
    } catch {
      const msg = rateToSave.rateid === undefined ? "create" : "update";
      const errorMessage = `Failed to ${msg} hotel rate`;
      enqueueSnackbar(errorMessage, {
        variant: "error",
        tag: "createUpdateHotelRateError",
      });
    }
  };

  const handleDeletion = async (rateid) => {
    let continueDelete = await textConfirmation({
      title: `Are you sure you want to delete this rate?`,
      message:
        "Please enter the rate name EXACTLY as shown in the input box below to delete.",
      textConfirmation: hotelRates.find((x) => x.rateid == rateid).name,
    });

    if (!continueDelete) return;

    try {
      let rates = [...hotelRates];
      rates = rates.filter((x) => x.rateid !== rateid);
      hotelInterfaceData.rates = [...rates];
      await dispatchSetting(
        deviceID,
        "hotelinterface",
        JSON.stringify(hotelInterfaceData)
      );
      setHotelRates([...rates]);
    } catch (error) {
      enqueueSnackbar("Failed to delete hotel rate", {
        variant: "error",
        tag: "failedToDeleteHotelRateError",
      });
    }
  };

  const fetchAccessGroups = useCallback(
    async (scopeAwareFacilityID) => {
      let response;
      try {
        response = await accessGroupService.GetAccessGroups(scopeAwareFacilityID);
      } catch {
        enqueueSnackbar("Failed to retrieve Access Groups", {
          variant: "error",
          tag: "accessGroupFetchError",
        });
        return;
      }
      if (response.data.totalCount > 0) {
        const filteredAccessGroups = response.data.collection.filter(
          (accessGroup) => accessGroup.type == "Normal"
        );
        let accessGroups = filteredAccessGroups.map((x) => {
          return { accessGroupID: x.accessGroupID, name: x.name };
        });
        setAccessGroups(accessGroups);
      }
    },
    [scopeAwareFacilityID]
  );

  useEffect(() => {
    fetchAccessGroups(scopeAwareFacilityID);
  }, [scopeAwareFacilityID]);

  return (
    <>
      <Grid container className={clsx(`title-container`, classes.container)}>
        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          lg={11}
          xl={11}
          style={{ height: "50px" }}
        >
          <Typography
            variant="h5"
            color="primary"
            component="h2"
            name="title"
            className={clsx("title", classes.title)}
          >
            Hotel Rates List
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={1}
          lg={1}
          xl={1}
          className={clsx("add-button-grid", classes.addButtonGrid)}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={onAddClick}
            className={clsx("add-button")}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Divider style={{ marginBottom: 15 }}></Divider>
        </Grid>

        <HotelRatesMappingTable
          hotelRatesList={hotelRates}
          accessGroups={accessGroups}
          onEditClick={onEditClick}
          onDeleteClick={handleDeletion}
        />
      </Grid>
      <HotelRatesSideDrawer
          hotelRate={hotelRate}
          accessGroups={accessGroups}
          handleSave={handleSave}
          handleCancel={handleDrawerClose}
          drawerOpen = {drawerOpen}
        />
    </>
  );
};

HotelRatesConfiguration.defaultProps = {
  hotelInterfaceSettings: "{}",
};

HotelRatesConfiguration.propTypes = {
  deviceID: PropTypes.string,
  hotelInterfaceSettings:PropTypes.string,
};

export default React.memo(HotelRatesConfiguration);