import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Tooltip
} from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const GPIOMappingTable = ({ IOMappingList, onEditClick, onDeleteClick, ioMappingIDUsedForRateTrigger }) => {
  const classes = useStyles();
  const { height } = useWindowDimensions();

  const getPortNumber = (boardType, number) => {
    switch (boardType?.toLowerCase()) {
      case "sealevel":
      case "ami":
        return `I/O Point ${number + 1}`;
      case "direct gpio":
        return `Pin ${number}`;
    }
  };

  return (
    <>
      <TableContainer
        className={clsx("table-container", classes.tableContainer)}
        style={{ maxHeight: height * 0.8 }}
      >
        <Table className={clsx("table", classes.table)} stickyHeader>
          <TableHead>
            <TableRow className={clsx("table-header-row")}>
              <TableCell className={clsx("name", classes.tableCells)}>
                Name
              </TableCell>
              <TableCell className={clsx("Board", classes.tableCells)}>
                Board
              </TableCell>
              <TableCell className={clsx("Pin", classes.tableCells)}>
                Location
              </TableCell>
              <TableCell className={clsx("Input", classes.tableCells)}>
                Input / Output
              </TableCell>
              <TableCell className={clsx("Actions", classes.tableCells)}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {IOMappingList?.map((row) => {
              return (
                <TableRow
                  className={clsx("table-body-row", row.friendlyName)}
                  key={row.ioMappingID}
                  data-row={row.ioMappingID}
                >
                  <TableCell
                    className={clsx("Name", classes.tableCells)}
                    data-name={row.friendlyName}
                  >
                    {row.friendlyName}
                  </TableCell>
                  <TableCell
                    className={clsx("Board", classes.tableCells)}
                    data-board={row.boardType}
                  >
                    {row.boardType}
                  </TableCell>
                  <TableCell
                    className={clsx("Pin", classes.tableCells)}
                    data-port={row.gpioPortNumber}
                  >
                    {getPortNumber(row.boardType, row.gpioPortNumber)}
                  </TableCell>
                  <TableCell
                    className={clsx("input", classes.tableCells)}
                    data-input={row.gpioType}
                  >
                    {row.gpioType}
                  </TableCell>
                  <TableCell
                    className={clsx("action-buttons", classes.actionTableCell)}
                  >
                    <Button
                      className={clsx("edit-button")}
                      variant="outlined"
                      color="primary"
                      onClick={() => onEditClick(row.ioMappingID)}
                    >
                      Edit
                    </Button>
                    <Tooltip title={row.ioMappingID == ioMappingIDUsedForRateTrigger ? "This input is currently being used as trigger for secondary rate." : ""}>
                      <span>
                        <Button
                          className={clsx("delete-button", classes.deleteButton)}
                          variant="outlined"
                          color="secondary"
                          onClick={() => onDeleteClick(row.ioMappingID)}
                          disabled={row.ioMappingID == ioMappingIDUsedForRateTrigger}
                        >
                          Delete
                        </Button>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GPIOMappingTable;
